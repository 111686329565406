import { Box, Card, Typography } from "@mui/material";

export const LabelCard: React.FC<{
  heading: string;
  icon?: React.ReactNode;
  sx?: any;
}> = (props) => {
  const { heading, icon } = props;

  return (
    <>
      <Box
        sx={{
          mb: 1,
          width: "100%",
          backgroundColor: "primary.main",
          borderRadius: "5px",
          padding: "16px 12px",
          ...props.sx,
        }}
      >
        <Box display={"flex"} alignItems={"center"}>
          {icon && (
            <Box marginRight={1} sx={{ svg: { color: "#fff" } }}>
              {" "}
              {icon}
            </Box>
          )}
          <Typography
            variant="h5"
            color={"white"}
            textAlign={"start"}
            fontSize={"0.95rem"}
          >
            {heading}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export const TableSellSplit: React.FC<{ heading: string; sx?: any }> = (
  props,
) => {
  const { heading } = props;

  return (
    <>
      <Card
        sx={{ padding: "16px", backgroundColor: "primary.main", ...props.sx }}
      >
        <Typography
          variant="h5"
          color={"white"}
          textAlign={"start"}
          fontSize={"0.9rem"}
        >
          {heading}
        </Typography>
      </Card>
    </>
  );
};

export const LabelCardV2: React.FC<{
  heading: string;
  icon?: React.ReactNode;
  sx?: any;
}> = (props) => {
  const { heading, icon } = props;

  return (
    <>
      <Box
        sx={(theme) => ({
          mb: 1,
          width: "100%",
          borderBottom: "1px solid " + theme.palette.grey[600],
          padding: "5px 12px",
          paddingLeft: 0,
          ...props.sx,
        })}
      >
        <Box display={"flex"} alignItems={"center"}>
          {icon && (
            <Box marginRight={1} sx={{ svg: { color: "#fff" } }}>
              {" "}
              {icon}
            </Box>
          )}
          <Typography
            variant="h5"
            textAlign={"start"}
            fontSize={"1.3rem"}
            sx={(theme) => ({
              color: theme.palette.grey[600],
            })}
          >
            {heading}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
