import React, { useEffect, useState } from 'react'
import { useDispatchWrapper } from '../../../hooks';
import { extractProductWithAiAsync, uploadProductDocumentAsync } from '../../../redux/file-upload-with-ai/file-upload-with-ai.actions';
import { PageContainer } from '../../../components/container/PageContainer';
import { StandardCard } from '../../../components/Cards';
import { Box, Button, Grid, Stack } from '@mui/material';
import { CustomFormLabel, CustomTextField } from '../../../components/formsComponents';
import { PreviewExcelProductsTable } from './PreviewExcelProductsTable';
import { useFormik } from 'formik';
import { FileUpload } from '../../../components/FileUpload/FileUpload';
import { defaultAiPdfUpload } from '../../../redux/file-upload-with-ai/file-upload-with-ai.state';
import { closeLoaderWithMessage, openLoaderWithMessage } from '../../../redux/messages/messagesActions';
import { generalFileUploadAsync } from '../../template/excelTemplate/ExcelTemplate.helpers';
import { PreviewPdfFile } from './PreviewPdfFile';
import { getFileNameFromUrl } from './../../../components/FileUpload/utils/getFileNameFromUrl';
import { getFileNameFromFilePath } from '../ExcelImports/ImportExcelProducts';

interface IPdfFileProductsScreenProps {
    filePath: string
    onSaveSuccess: () => void
    onFileUploadSuccess: (path: string) => void
}
export const PdfFileProductsScreen: React.FC<IPdfFileProductsScreenProps> = ({
    filePath,
    onSaveSuccess,
    onFileUploadSuccess
}) => {
    const dispatch = useDispatchWrapper();
    const [documentUUID, setDocumentUUID] = useState<string | null>(null)
    // useEffect(() => {
    //     if (uuid && templateUUID) {
    //         dispatch(extractProductsFromExcelFileAsync(uuid, templateUUID, (isSuccess, data) => {
    //             if (isSuccess && data) {

    //             }
    //         }))
    //     }
    // }, [uuid, templateUUID])

    const [fileToUpload, setfileToUpload] = useState<File | null>(null)

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        setValues,
        setFieldValue,
        isValid,
        resetForm,
    } = useFormik({
        initialValues: {
            document_name: "",
            template_uuid: "",
            file: null
        },
        validate: (values) => {
            const errors: any = {};
            if (!values.document_name) {
                errors.document_name = "*This is required field"
            }
            return errors;
        },
        onSubmit: (values) => {
            dispatch(
                uploadProductDocumentAsync(
                    {
                        ...defaultAiPdfUpload,
                        file_type: "PDF",
                        name: values.document_name || (values.file as any)?.name,
                        file_path: filePath
                    },
                    (isSuccess, data) => {
                        if (isSuccess && data) {
                            dispatch(openLoaderWithMessage("Extracting Products...!"))
                            dispatch(extractProductWithAiAsync(data.product_documents_uuid || "", (isSuccess, sdata) => {
                                if (isSuccess) {
                                    setDocumentUUID(data.product_documents_uuid)
                                }
                                dispatch(closeLoaderWithMessage())
                            }))
                        }
                    },
                ),
            );
        },
    });


    useEffect(() => {
      if(!filePath)return
        setFieldValue("document_name", getFileNameFromFilePath(filePath))
    }, [filePath])
    
    const handleFileUploadClick = () => {
        dispatch(openLoaderWithMessage("File uploading in progress...!"))
        generalFileUploadAsync(fileToUpload as File, {
            template_unique_no: ""
        }).then((file_path) => {
            onFileUploadSuccess(file_path)
        }).finally(() => {
            dispatch(closeLoaderWithMessage())
        })
    }


    return (
        <PageContainer
            title={"Template Products"}
            description="this is inner page"
        >
            <StandardCard heading={`Upload New Line Sheet`}>
                <Box component={"form"} onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <CustomFormLabel>Document Name</CustomFormLabel>
                            <CustomTextField
                                id='document_name'
                                name="document_name"
                                variant="outlined"
                                size="small"
                                fullWidth
                                disabled
                                value={values.document_name}
                                onChange={handleChange}
                                error={errors.document_name ? true : false}
                                helperText={errors.document_name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomFormLabel>File Upload</CustomFormLabel>
                            <FileUpload
                                value={null}
                                onChange={(file) => setfileToUpload(file)}
                                uploadType={"PDF"}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                type={"submit"}
                                disabled={!fileToUpload}
                                onClick={handleFileUploadClick}
                            >
                                Upload
                            </Button>
                        </Grid>
                    </Grid>
                    <Stack
                        mt={2}
                        direction={"row"}
                        justifyContent={"flex-end"}
                        alignItems={"flex-end"}
                    >
                        <Button
                            variant="contained"
                            type={"submit"}
                            disabled={!values.document_name}
                        >
                            Extract Products
                        </Button>
                    </Stack>
                </Box>
            </StandardCard>

            {documentUUID && <PreviewExcelProductsTable documentUUID={documentUUID} onSaveSuccess={onSaveSuccess} />}
            <PreviewPdfFile filePath={filePath} />

        </PageContainer>
    )
}
