import { Box, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { api } from '../../../api/api';
import { useDispatchWrapper } from '../../../hooks';

export type IFileExtension = "IMAGE" | "PDF" | "EXCEL" | "OTHER";
export const getFileExtensionType = (fileName: string): IFileExtension => {
    const extension = fileName.split(".").pop();
    if (
        extension === "jpg" ||
        extension === "jpeg" ||
        extension === "png" ||
        extension === "webp" ||
        extension === "jfif" ||
        extension === "tif"
    )
        return "IMAGE";
    if (extension === "pdf") return "PDF";
    if (extension === "xls" || extension === "xlsx") return "EXCEL";
    return "OTHER";
};
export const getBase64Extension = (extension: string): string => {
    const fileType = getFileExtensionType(extension);
    if (fileType === "IMAGE") return `data:image/${extension};base64`;
    if (fileType === "EXCEL")
        return `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64`;
    return `data:application/${extension};base64`;
};

const arrayBufferToBase64V2 = (buffer: any, extension: string): string => {
    return `${getBase64Extension(extension)},${btoa(
        new Uint8Array(buffer).reduce(function (data, byte) {
            return data + String.fromCharCode(byte);
        }, ""),
    )}`;
};

export const downloadSingleFileAsync = async (fileUrl: string) =>
    new Promise<{ fileName: string; data: string; type: IFileExtension }>(
        (resolve, reject) => {
            const payload = {
                type: "json",
                keys: [fileUrl],
            };
            api.post("/general/download-files", payload)
                .then((res) => {
                    const fileName = fileUrl.split("/")[fileUrl.split("/").length - 1];
                    const fileExtension = fileName.split(".")[1];
                    const base64Data = arrayBufferToBase64V2(
                        res.data[0]?.data,
                        fileExtension,
                    );
                    resolve({
                        fileName: fileName,
                        data: base64Data,
                        type: getFileExtensionType(fileUrl),
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        },
    );
export const downloadSingleFileAsyncV2 = async (fileUrl: string) =>
    new Promise<{ fileName: string; data: string; type: IFileExtension }>(
        async (resolve, reject) => {
            try {
                const res = await api.get(`/general/get-signed-url?key=${fileUrl}`, {
                    responseType: 'arraybuffer',
                });

                const fileName = fileUrl.split("/").pop() || "downloaded_file";
                const fileExtension = fileName.split(".").pop() || "";

                const base64Data = arrayBufferToBase64V2(res.data, fileExtension);

                resolve({
                    fileName,
                    data: base64Data,
                    type: getFileExtensionType(fileUrl),
                });
            } catch (err) {
                reject(err);
            }
        }
    );


interface IPreviewPdfFileProps {
    filePath: string
}

export const PreviewPdfFile: React.FC<IPreviewPdfFileProps> = ({
    filePath
}) => {
    const dispatch = useDispatchWrapper()
    const theme = useTheme();

    const [fileToView, setFileToView] = useState<string | null>(null)
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (!filePath) return
        setLoading(true);
        downloadSingleFileAsyncV2(filePath)
            .then((result) => {
                setFileToView(result.data)
            }).finally(() => {
                setLoading(false);
            })

    }, [filePath])

    if (loading) {
        return (
            <Box
                sx={{
                    border: `1px solid ` + theme.palette.grey[200],
                    borderRadius: '5px',
                    minHeight: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}

            >
                <Box>
                    <Typography variant='h4' textAlign={'center'}>
                        Preparing Your File for Preview...
                    </Typography>
                    <Typography variant='body1' textAlign={'center'}>
                        Thank you for your patience as we retrieve and process the file. This may take a few moments.
                    </Typography>
                </Box>

            </Box>
        )
    }


    return (
        <>

            {fileToView && (
                <iframe title='pdf-file-view'
                    src={`${fileToView}`}
                    // src={`https://api.easysupply.ai/api/v1/general/get-signed-url?key=${filePath}`}
                    style={{ width: "100%", height: "auto", minHeight: "90vh" }}
                />)}
        </>
    )
}


interface IPreviewExcelFileProps {
    filePath: string
}

export const PreviewExcelFile: React.FC<IPreviewExcelFileProps> = ({
    filePath
}) => {
    return <iframe title='excel-file-view'
        src={`${process.env["REACT_APP_EXCEL_VIEW_URL_PREFIX"]}${process.env["REACT_APP_SERVER_URL"]}/general/get-signed-url?key=${filePath}`}
        style={{ width: "100%", height: "auto", minHeight: "90vh" }}
    />
}