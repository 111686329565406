import { LoadState } from "../../../constants/enums";
import { ISupplierBranch, ISupplierBranchState } from "./supplier-branch.types";


export const defaultSupplierBranch: ISupplierBranch = {
  supplier_branch_uuid: null,
  supplier_uuid: "",
  supplier_branch_name: "",
  supplier_name: "",
  supplier_branch_gst_in: null,
  pan_no: null,
  invoice_no_sequence: null,
  supplier_branch_address_line1: null,
  supplier_branch_address_line2: null,
  supplier_branch_address_city: null,
  supplier_branch_address_state: null,
  supplier_branch_address_pincode: null,
  supplier_branch_address_country: null,
  supplier_branch_mobile: null,
  supplier_branch_phone_number: null,
  supplier_branch_website: null,
  supplier_branch_mail_id: null,
  status: "ACTIVE",
};

export const defaultSupplierBranchState: ISupplierBranchState = {
  supplier_Branch_list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  single_supplier_branch: {
    data: defaultSupplierBranch,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
