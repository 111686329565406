import React from "react";
import { IDialogProps } from "./interfaces/IDialogProps";
import {
	Dialog as MuiDialog,
	DialogContent,
	Divider,
	DialogActions,
	Box,
	DialogTitle,
	Button,
	Typography,
	Theme,
	IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

export const Dialog: React.FC<IDialogProps> = (props) => {
	const {
		open,
		title,
		subtitle,
		size,
		fullScreen = false,
		hideCloseIcon = false,
		contentWrappedWithForm,
		contentSx,
		actions,
		onClose,
		leftHeadingNode,
		rightHeadingNode,
	} = props;

	const generateActions = () => {
		if (actions) {
			return (
				<DialogActions sx={{ marginTop: 0.5 }}>
					{actions.map((action) => {
						return (
							<Button
								variant={action.variant}
								color="primary"
								type={action.type}
								disabled={action.disabled}
								onClick={action.onClick}
								size="large"
								sx={{ marginRight: "5px" }}
							>
								{action.label}
							</Button>
						);
					})}
				</DialogActions>
			);
		}
	};

	const renderForm = () => {
		const actionsButtons = generateActions();
		if (contentWrappedWithForm) {
			return (
				<form onSubmit={contentWrappedWithForm.onSubmit}>
					<DialogContent sx={{ overflowY: "auto", maxHeight: "70vh" }}>
						{props.children}
					</DialogContent>
					<Divider />
					{actionsButtons}
				</form>
			);
		}
		return (
			<>
				<DialogContent sx={contentSx}>{props.children}</DialogContent>
				<Divider />
				{actionsButtons}
			</>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	};

	return (
		<MuiDialog
			open={open}
			fullScreen={fullScreen}
			onClose={onClose}
			sx={{ zIndex: 1200 }}
			maxWidth={size}
			fullWidth
			TransitionComponent={fullScreen ? Transition : undefined}
		>
			<DialogTitle
				sx={(theme: Theme) => ({
					background: theme.palette.primary.main,
					color: "#fff",
				})}
			>
				<Box
					display="flex"
					justifyContent={"space-between"}
				>
					<Box
						display={"flex"}
						flexDirection={"row"}
						alignItems={"center"}
						gap={2}
					>
						<Typography
							variant="h3"
							fontWeight={"bold"}
						>
							{title}
						</Typography>
						{leftHeadingNode}
					</Box>
					<Box
						display={"flex"}
						alignItems={"center"}
						flexDirection={"row"}
						gap={2}
					>
						{rightHeadingNode}
						{!hideCloseIcon && (
							<IconButton
								sx={{ cursor: "pointer", color: "#fff" }}
								onClick={onClose}
							>
								<CloseIcon />
							</IconButton>
						)}
					</Box>
				</Box>
				<Typography
					variant="body1"
					color="rgb(38, 38, 38)"
				>
					{subtitle}
				</Typography>
			</DialogTitle>
			<Divider />
			{renderForm()}
		</MuiDialog>
	);
};

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return (
		<Slide
			direction="left"
			ref={ref}
			{...props}
		/>
	);
});
