import { LoadState } from "../../../constants/enums";
import { ISupplierAttachment, ISupplierAttachmentState } from "./supplier-attachments.types";

export const defaultSupplierAttachment: ISupplierAttachment = {
    supplier_attachment_uuid: null,
    supplier_attachment_name: "",
    supplier_uuid: "",
    supplier_name: "",
    is_vendor: "NO",
    link: "",
    status: "ACTIVE",
};


export const defaultSupplierAttachmentState: ISupplierAttachmentState = {
    supplier_attachments_list: {
        data: [],
        totalRecords: 0,
        loading: LoadState.NotLoaded,
        error: null,
    },
    single_supplier_attachment: {
        data: defaultSupplierAttachment,
        loading: LoadState.NotLoaded,
        error: null,
    },
};
