import { action } from "typesafe-actions";
import { IQueryParams } from "../../common/common.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { getSearchQuery } from "../../common/helpers";
import { fetchProductsListProgress } from "../../products";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { uploadFile } from "../../../helpers/uploadFile";
import { ISupplierAttachment } from "./supplier-attachments.types";

export const FETCH_SUPPLIER_ATTACHMENT_LIST_PROGRESS =
  "FETCH_SUPPLIER_ATTACHMENT_LIST_PROGRESS";
export const FETCH_SUPPLIER_ATTACHMENT_LIST_SUCCESS =
  "FETCH_SUPPLIER_ATTACHMENT_LIST_SUCCESS";
export const FETCH_SUPPLIER_ATTACHMENT_LIST_FAILED =
  "FETCH_SUPPLIER_ATTACHMENT_LIST_FAILED";

export const fetchSupplierAttachmentListProgress = () =>
  action(FETCH_SUPPLIER_ATTACHMENT_LIST_PROGRESS);
export const fetchSupplierAttachmentListSuccess = (
  data: ISupplierAttachment[],
  totalRecords: number,
) =>
  action(FETCH_SUPPLIER_ATTACHMENT_LIST_SUCCESS, { data, totalRecords });
export const fetchSupplierAttachmentListFailed = () =>
  action(FETCH_SUPPLIER_ATTACHMENT_LIST_FAILED);

export const fetchSupplierAttachmentListAsync =
  (
    queryParams: IQueryParams,
    customerUUID: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchSupplierAttachmentListProgress());
      let finalUrl = `/supplier/get-supplier-attachment${searchQuery}&supplier_uuid=${customerUUID}&status=ACTIVE`;
      const res = await api.get(finalUrl);
      const data: ISupplierAttachment[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      dispatch(fetchSupplierAttachmentListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchSupplierAttachmentListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertSupplierAttachmentAsync =
  (
    payload: ISupplierAttachment,
    file: any | null,
    onCallback: (
      isSuccess: boolean,
      returnedData?: ISupplierAttachment,
    ) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      let path = "";
      const asPayload = {
        supplier_name: payload.supplier_name,
      };
      path = await uploadFile(file, "SUPPLIER", payload.link || "", asPayload);
      const { create_ts, insert_ts, rowId, ...rest } = payload;
      const res = await api.post("/supplier/upsert-supplier-attachment", {
        ...rest,
        link: path,
      });
      const data = res.data.data;
      onCallback(true, data);
      dispatch(
        showMessage({
          type: "success",
          message: "Document saved successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_SUPPLIER_ATTACHMENT =
  "CLEAR_SUPPLIER_ATTACHMENT";
export const CLEAR_SUPPLIER_ATTACHMENT_STATE =
  "CLEAR_SUPPLIER_ATTACHMENT_STATE";

export const clearSupplierAttachmentSync = () =>
  action(CLEAR_SUPPLIER_ATTACHMENT);
export const clearSupplierAttachmentListSync = () =>
  action(CLEAR_SUPPLIER_ATTACHMENT_STATE);
