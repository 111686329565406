import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthProvider/AuthProvider";
import { rolesDashboards } from "./rolesDashboard";

export const PublicRoute: React.FC<{ children: React.ReactNode }> = (props) => {
  const { isLogin, userInfo,directLogin } = useAuth();


  if (isLogin && userInfo.token) {
    //const path = rolesDashboards[userInfo.role as "ADMIN"];
    const path = "/dashboard";
    if (path) {
      return <Navigate to={path} />;
    }
  }
  return <>{props.children}</>;
};
