import React from "react";
import { Button, Grid, Stack } from "@mui/material";
import { useFormik } from "formik";
import { useDispatchWrapper, useLoggedInUserInfo } from "../../../../hooks";
import { IStoreState } from "../../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import { RightPanel } from "../../../../components/RightPanel";
import { LoadState } from "../../../../constants/enums";
import { TextFieldWithLabel } from "../../../../components/TextFieldWithLabel/TextFieldWithLabel";
import {
	ControlledCustomSelect,
	CustomFormLabel,
} from "../../../../components/formsComponents";
import {
	available_active_status_list,
	available_organization_type_list,
} from "../../../../constants/constants";
import {
	clearSingleSupplier,
	ISupplier,
	upsertSingleSupplierAsync,
} from "../../../../redux/supplier";

export interface ISupplierRightPanelProps {
	open: boolean;
	customerName?: string;
	onClose: () => void;
	onComplete: (data: ISupplier) => void;
}

export const SupplierRightPanel: React.FC<ISupplierRightPanelProps> = (
	props
) => {
	const { open, customerName, onComplete, onClose } = props;
	const {
		data: customer,
		loading,
		error,
	} = useSelector(
		(storeState: IStoreState) => storeState.supplier.single_supplier
	);
	const dispatch = useDispatchWrapper();
	const { user_uuid } = useLoggedInUserInfo();

	const { values, errors, setValues, handleChange, handleSubmit } = useFormik({
		initialValues: { ...customer },
		validateOnBlur: false,
		validateOnChange: false,
		validate: (values) => {
			const errors: any = {};
			if (!values.supplier_name) {
				errors.supplier_name = "This field is required";
			}
			return errors;
		},
		onSubmit: async (values) => {
			let payload: ISupplier = {
				...values,
				created_by_uuid: user_uuid,
			};
			dispatch(
				upsertSingleSupplierAsync(payload, (isSuccess, customer) => {
					if (isSuccess && customer) {
						onComplete(customer);
					}
				})
			);
		},
	});

	React.useEffect(() => {
		let customerValues = { ...customer };
		if (customerName) {
			customerValues.supplier_name = customerName;
		}
		setValues(customerValues);
	}, []);

	React.useEffect(() => {
		return () => {
			dispatch(clearSingleSupplier());
		};
	}, []);

	return (
		<RightPanel
			open={open}
			heading="Create Customer"
			// onClose={onClose}
			width="60%"
			onClose={onClose}
			isWrappedWithForm
			onFormSubmit={handleSubmit}
			actionButtons={() => (
				<Stack
					direction={"row"}
					spacing={2}
				>
					<Button
						variant="contained"
						type="button"
						onClick={onClose}
					>
						Close
					</Button>
					<Button
						variant="contained"
						type="submit"
						disabled={loading === LoadState.InProgress}
					>
						Save
					</Button>
				</Stack>
			)}
		>
			<Grid
				container
				spacing={2}
			>
				<Grid
					item
					xs={12}
					lg={4}
				>
					<TextFieldWithLabel
						heading={"Supplier Name"}
						type="text"
						id="supplier_name"
						fullWidth
						value={values.supplier_name}
						onChange={handleChange}
						error={errors.supplier_name ? true : false}
						helperText={errors.supplier_name}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					lg={4}
				>
					<TextFieldWithLabel
						heading={"Supplier Email"}
						type="text"
						id="supplier_email"
						fullWidth
						value={values.supplier_email}
						onChange={handleChange}
						error={errors.legal_entity ? true : false}
						helperText={errors.legal_entity}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					lg={4}
				>
					<TextFieldWithLabel
						heading={"Legal Entity"}
						type="text"
						id="legal_entity"
						fullWidth
						value={values.legal_entity}
						onChange={handleChange}
						error={errors.legal_entity ? true : false}
						helperText={errors.legal_entity}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					lg={4}
				>
					<CustomFormLabel>Organization Type</CustomFormLabel>
					<ControlledCustomSelect
						name="organization_type"
						fullWidth
						options={available_organization_type_list}
						displayEmpty
						placeholder="Select one"
						value={values.organization_type}
						onChange={handleChange}
						error={errors.organization_type ? true : false}
						helperText={errors.organization_type}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					lg={4}
				>
					<TextFieldWithLabel
						heading={"Supplier Website"}
						type="text"
						id="supplier_website"
						fullWidth
						error={errors.supplier_website ? true : false}
						helperText={errors.supplier_website}
						onChange={handleChange}
					/>
				</Grid>

				<Grid
					item
					xs={12}
					lg={4}
				>
					<CustomFormLabel>Status</CustomFormLabel>
					<ControlledCustomSelect
						name="status"
						fullWidth
						value={values.status}
						onChange={handleChange}
						options={available_active_status_list}
						displayEmpty
						placeholder="Select one"
					/>
				</Grid>
			</Grid>
		</RightPanel>
	);
};
