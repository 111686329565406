import { LoadState } from "../../../constants/enums";
import { ISupplierBankDetails, ISupplierBankDetailsState } from "./supplier-bank-details.types";

export const defaultSupplierBankDetails: ISupplierBankDetails = {
    bank_details_uuid: null,
    supplier_uuid: "",
    supplier_name: "",
    bank_name: "",
    branch: null,
    account_no: "",
    ifsc_code: null,
    status: "ACTIVE",
};


export const defaultSupplierBankDetailsState: ISupplierBankDetailsState = {
    supplier_bank_details_list: {
        data: [],
        totalRecords: 0,
        loading: LoadState.NotLoaded,
        error: null,
    },
    single_supplier_bank_details: {
        data: defaultSupplierBankDetails,
        loading: LoadState.NotLoaded,
        error: null,
    },
};
