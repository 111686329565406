/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Box,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import React, { SyntheticEvent } from "react";
import { CustomFormLabel, CustomTextField } from "../formsComponents";
import { debounce } from "lodash";
import { api } from "../../api/api";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { removeDuplicateItems } from "../../helpers/removeDuplicateItems";
import { initialTemplatesState, ITemplate } from "../../redux/templates";

export interface IProductsTemplateAutoSearchProps {
  label?: string;
  hideAddButton?: boolean;
  disabled?: boolean;
  value: string;
  onSelect: (value: ITemplate) => void;
  error?: string | boolean;
}
export const ProductsTemplateAutoSearch: React.FC<
  IProductsTemplateAutoSearchProps
> = (props) => {
  const { label, value, onSelect, hideAddButton, error, disabled } = props;
  const [options, setOptions] = React.useState<readonly ITemplate[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [search, setSearchText] = React.useState<any>("");
  const [openProduct, setOpenProduct] = React.useState(false);

  const fetchSuggestion = async (enteredValue: string) => {
    setLoading(true);
    try {
      let searchQuery = "";
      if (enteredValue.length > 0) {
        searchQuery = `columns=template_name&value=${enteredValue}&`;
      }
      const res = await api.get(
        `/template/get-template?${searchQuery}status=ACTIVE&pageNo=${1}&itemPerPage=${10}`,
      );
      const data: ITemplate[] = res.data.data;
      setOptions(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const debounceFn = React.useCallback(debounce(fetchSuggestion, 800), []);

  const getOptionLabel = (option: string | ITemplate) => {
    if (typeof option === "string") return option;
    return `${option.template_name}`;
  };

  const getValue = () => {
    return (
      options.find(
        (option) =>
          option.template_uuid === value,
      ) || null
    );
  };

  const handleToggle = () => {
    setOpenProduct(!openProduct);
  };

  const handleComplete = (product: ITemplate) => {
    setOptions([product, ...options]);
    onSelect(product);
    handleToggle();
  };

  React.useEffect(() => {
    debounceFn("");
  }, []);

  React.useEffect(() => {
    debounceFn(search);
  }, [search]);

  React.useEffect(() => {
    if (value && value?.length > 0) {
      const option: ITemplate = {
        ...initialTemplatesState["template"],
        template_uuid: value,
        template_name: value,
      };
      setOptions(
        removeDuplicateItems(
          [option, ...options],
          "product_template_uuid",
        ) as ITemplate[],
      );
    }
  }, [value]);

  return (
    <>
      <Box display="flex" justifyContent={"flex-start"} alignItems="center">
        {label && (
          <CustomFormLabel display={"block"}>{` ${label}  `}</CustomFormLabel>
        )}
      </Box>

      <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
        <Autocomplete
          id="google-map-demo"
          fullWidth
          freeSolo
          disabled={disabled}
          sx={{
            ".MuiOutlinedInput-root": {
              paddingTop: "2px",
              paddingBottom: "2px",
              fontSize: "0.8rem",
              color: "rgb(38, 38, 38)",
              width: "100%",
              minWidth: 220,
              background: (theme) => theme.palette.background.paper,
            },
          }}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={(option, value) =>
            typeof option !== "string" && typeof value !== "string"
              ? option.template_name === value.template_name
              : option === value
          }
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          value={getValue()}
          loading={loading}
          noOptionsText="No Items"
          onChange={(
            event: SyntheticEvent<Element, Event>,
            newValue: string | ITemplate | null,
          ) => {
            if (newValue && typeof newValue !== "string") {
              onSelect(newValue);
            }
          }}
          onInputChange={(event, newInputValue) => {
            if ((event && event.type === "change") || !newInputValue) {
              setSearchText(newInputValue);
            }
          }}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading && <CircularProgress color="inherit" size={20} />}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        {/* {!label &&
          !hideAddButton &&
          search.length > 0 &&
          options.length === 0 &&
          !loading && (
            <Box>
              <Tooltip title="Create Product">
                <Fab
                  size="small"
                  color="primary"
                  aria-label="add"
                  onClick={handleToggle}
                >
                  <AddIcon />
                </Fab>
              </Tooltip>
            </Box>
          )} */}
      </Stack>
      {/* {openProduct && (
        <ProductRightPanel
          open={openProduct}
          onClose={handleToggle}
          onComplete={handleComplete}
        />
      )} */}
    </>
  );
};
