import { uploadFile } from "../../../helpers/uploadFile"



export const getOptionsFromMappings = (data: any): any => {
    if (!data) return []
    // const new_Data =  Object.keys(data).map((key) => {
    //     if (typeof data[key] === "object") {
    //         return getOptionsFromMappings(data[key]).toString()
    //     } else {
    //         return `${key}`
    //     }
    // }).join().split(',').filter((item) => typeof item === "string" && item !== "null" && item !== "undefined" && item !== "")
    // return new_Data

    let keyArray: string[] = []
    Object.keys(data).forEach((key) => {
        if (typeof data[key] === "object") {
            getOptionsFromMappings(data[key]).toString()
        }
        keyArray.push(key)
    })//.join().split(',').filter((item) => typeof item === "string" && item !== "null" && item !== "undefined" && item !== "")
    return keyArray
}


export const getAdditionalAttributeKey = (attributes: { [key: string]: string }, attr_length: number): string => {
    let newObj: any = { ...attributes }
    let newKey = `field${attr_length}`
    if (newKey in newObj) {
        return getAdditionalAttributeKey(attributes, attr_length + 1)
    }
    return newKey
}




export const generalFileUploadAsync = (file: File, asPayload: {}) => new Promise<string>(async (resolve, reject) => {
    try {
        const path = await uploadFile(
            file,
            "TEMPLATE",
            "",
            asPayload
        );
        resolve(path)
    } catch (error) {
        reject(error)
    }
})