import React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import "./App.css";
import { RTL } from "./layout/fullLayout/customizer/RTL";
import { ThemeSettings } from "./layout/fullLayout/customizer/ThemeSettings";
import { IStoreState } from "./redux/initialStoreState";
import { Router } from "./router/router";
import { useDispatchWrapper } from "./hooks";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TableProvider } from "./components/Table/contexts/TableProvider";

function App() {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const dispatch = useDispatchWrapper();
  const customizer = useSelector(
    (state: IStoreState) => state.customizerReducer,
  );

  React.useEffect(()=>{
    // function applyZoomOut(scale: any) {
    //   document.body.style.transform = `scale(${scale})`;
    //   document.body.style.transformOrigin = "top left";
    //   document.body.style.width = `${100 / scale}%`;  // Adjust width to counter scale down
    //   document.body.style.height = `${100 / scale}%`; // Adjust height to counter scale down
    // }
    
    // // Apply 80% zoom (0.8 scale)
    // applyZoomOut(0.82);
  },[])

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <TableProvider
          tableConfig={{
            stickyHeader: true,
          }}
        >
          <RTL direction={customizer.activeDir as "ltr"}>
            <CssBaseline />
            {routing}
          </RTL>
        </TableProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
