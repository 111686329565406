import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { initialTemplatesState } from "./templates.types";
import { templateActions } from ".";
import { CLEAR_TEMPLATES, FETCH_TEMPLATES_LIST_FAILED, FETCH_TEMPLATES_LIST_PROGRESS, FETCH_TEMPLATES_LIST_SUCCESS, FETCH_TEMPLATE_FAILED, FETCH_TEMPLATE_PROGRESS, FETCH_TEMPLATE_SUCCESS, UPDATE_ITEM_LABELING, UPDATE_TEMPLATE_IMAGE_PATH } from "./templateActions";
import { LoadState } from "../../constants/enums";
import {
  CLEAR_EXCEL_TEMPLATES,
  FETCH_EXCEL_TEMPLATE_FAILED,
  FETCH_EXCEL_TEMPLATE_PROGRESS,
  FETCH_EXCEL_TEMPLATE_SUCCESS
} from "./excel-template/excel-template.actions";


export const templateReducer = (
  state: IStoreState["templates"] = initialTemplatesState,
  action: templateActions
) => {
  switch (action.type) {
    case FETCH_TEMPLATES_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
      });
      return newState;
    }
    case FETCH_TEMPLATES_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_TEMPLATES_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
        draftState.list = [];
      });
      return newState;
    }
    case FETCH_TEMPLATE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.templateLoading = LoadState.InProgress;
        draftState.template = initialTemplatesState["template"];
        draftState.error = null;
      });
      return newState;
    }
    case FETCH_TEMPLATE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.templateLoading = LoadState.Loaded;
        draftState.template = data;
        draftState.error = null;
      });
      return newState;
    }
    case FETCH_TEMPLATE_FAILED: {
      const { errorMessage } = action.payload;

      const newState = produce(state, (draftState) => {
        draftState.templateLoading = LoadState.Failed;
        draftState.template = initialTemplatesState["template"];
        draftState.error = errorMessage;
      });
      return newState;
    }

    case UPDATE_TEMPLATE_IMAGE_PATH: {
      const { path } = action.payload;
      const newState = produce(state, (draftState) => {

        draftState.template.first_img_paths = path;

      });
      return newState;
    }

    case UPDATE_ITEM_LABELING: {
      const { currentImageIndex, rectangles } = action.payload;
      const newState = produce(state, (draftState) => {

        draftState.template.item_labeling[currentImageIndex] = rectangles

      });
      return newState;
    }



    case FETCH_EXCEL_TEMPLATE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.excel_template.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_EXCEL_TEMPLATE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.excel_template.loading = LoadState.Loaded;
        draftState.excel_template.data = data;
        draftState.excel_template.error = null;
      });
      return newState;
    }
    case FETCH_EXCEL_TEMPLATE_FAILED: {
      const { errorMessage } = action.payload;

      const newState = produce(state, (draftState) => {
        draftState.excel_template.loading = LoadState.NotLoaded;
        draftState.excel_template.data = initialTemplatesState["excel_template"]["data"];
        draftState.excel_template.error = errorMessage;
      });
      return newState;
    }


    case CLEAR_EXCEL_TEMPLATES: {
      const newState = produce(state, (draftState) => {
        draftState.excel_template.loading = LoadState.NotLoaded;
        draftState.excel_template.data = initialTemplatesState["excel_template"]["data"];
        draftState.excel_template.error = null;
      });
      return newState;
    }

    case CLEAR_TEMPLATES: {
      return initialTemplatesState;
    }

    default: {
      return state;
    }
  }
};
