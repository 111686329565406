import React from "react";
import { Navigate } from "react-router-dom";
import { useRoles } from "../security/RolesProvider/RolesProvider";
import { PremissionsProvider } from "../security/PremissionsProvider/PremissionsProvider";
import {
	getModulePremissions,
	isModuleAccess,
} from "../security/isModuleAccess";

interface IAuthorizedRouteProps {
	moduleIds: string[];
	allowFullAccess?: boolean;
	children: React.ReactNode;
}

export const AuthorizedRoute: React.FC<IAuthorizedRouteProps> = (props) => {
	const { moduleIds, allowFullAccess } = props;
	const { roles } = useRoles();

	console.log(moduleIds, roles, allowFullAccess);

	if (isModuleAccess(moduleIds, roles, allowFullAccess)) {
		const premissions = getModulePremissions(moduleIds, roles, allowFullAccess);

		return (
			<PremissionsProvider premissions={premissions}>
				{props.children}
			</PremissionsProvider>
		);
	}

	return <Navigate to="/access" />;
};
