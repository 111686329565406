export const getUserAuth = (): string | null => {
  const auth = localStorage.getItem("auth");
  if (auth) {
    return JSON.parse(auth).token;
  }
  return null;
};

export const isExternalLogin = (): string | null => {
  const auth = localStorage.getItem("auth");
  if(auth){
    return JSON.parse(auth).isExternalLogin
  }
  return null;
};
