import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../../api/api";
import { IQueryParams } from "../../common/common.types";
import { getSearchQuery } from "../../common/helpers";
import { IStoreState } from "../../initialStoreState";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../../messages/messagesActions";
import { ISupplierDeliveryAddress } from "./supplier-delivery-address.types";


export const FETCH_CUSTOMER_DELILVERY_ADDRESS_LIST_PROGRESS =
  "FETCH_CUSTOMER_DELILVERY_ADDRESS_LIST_PROGRESS";
export const FETCH_CUSTOMER_DELILVERY_ADDRESS_LIST_SUCCESS =
  "FETCH_CUSTOMER_DELILVERY_ADDRESS_LIST_SUCCESS";
export const FETCH_CUSTOMER_DELILVERY_ADDRESS_LIST_FAILED =
  "FETCH_CUSTOMER_DELILVERY_ADDRESS_LIST_FAILED";

export const fetchSupplierDeliveryAddressListProgress = () =>
  action(FETCH_CUSTOMER_DELILVERY_ADDRESS_LIST_PROGRESS);
export const fetchSupplierDeliveryAddressListSuccess = (
  data: ISupplierDeliveryAddress[],
  totalRecords: number,
) => action(FETCH_CUSTOMER_DELILVERY_ADDRESS_LIST_SUCCESS, { data, totalRecords });
export const fetchSupplierDeliveryAddressListFailed = () =>
  action(FETCH_CUSTOMER_DELILVERY_ADDRESS_LIST_FAILED);

export const fetchSupplierDeliveryAddressListAsync =
  (queryParams: IQueryParams, customerUUID?: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        const searchQuery = getSearchQuery(queryParams);
        dispatch(fetchSupplierDeliveryAddressListProgress());
        let finalUrl = `/supplier/get-supplier-delivery-address${searchQuery}&supplier_uuid=${customerUUID}`;
        const res = await api.get(finalUrl);
        const data: ISupplierDeliveryAddress[] = res.data.data;
        const totalRecords = res.data.totalRecords;
        dispatch(fetchSupplierDeliveryAddressListSuccess(data, totalRecords));
      } catch (err: any) {
        dispatch(fetchSupplierDeliveryAddressListFailed());
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

export const upsertSupplierDeliveryAddressAsync =
  (
    customerDeliveryAddress: ISupplierDeliveryAddress,
    onCallback: (isSuccess: boolean, customerDeliveryAddress?: ISupplierDeliveryAddress) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(saveLoaderProgress());
        const { create_ts, insert_ts, rowId, ...rest } = customerDeliveryAddress;
        const res = await api.post(
          "/supplier/upsert-supplier-delivery-address",
          rest,
        );
        const data = res.data.data;
        onCallback(true, data);
        dispatch(
          showMessage({
            type: "success",
            message: "Supplier DeliveryAddress saved successfully!",
            displayAs: "snackbar",
          }),
        );
      } catch (err: any) {
        onCallback(false);
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      } finally {
        dispatch(saveLoaderCompleted());
      }
    };

export const FETCH_CUSTOMER_DELILVERY_ADDRESS_PROGRESS = "FETCH_CUSTOMER_DELILVERY_ADDRESS_PROGRESS";
export const FETCH_CUSTOMER_DELILVERY_ADDRESS_SUCCESS = "FETCH_CUSTOMER_DELILVERY_ADDRESS_SUCCESS";
export const FETCH_CUSTOMER_DELILVERY_ADDRESS_FAILED = "FETCH_CUSTOMER_DELILVERY_ADDRESS_FAILED";

export const fetchSupplierDeliveryAddressProgress = () =>
  action(FETCH_CUSTOMER_DELILVERY_ADDRESS_PROGRESS);
export const fetchSupplierDeliveryAddressSuccess = (data: ISupplierDeliveryAddress) =>
  action(FETCH_CUSTOMER_DELILVERY_ADDRESS_SUCCESS, { data });
export const fetchSupplierDeliveryAddressFailed = (errorMessage: string) =>
  action(FETCH_CUSTOMER_DELILVERY_ADDRESS_FAILED, { errorMessage });

export const fetchSupplierDeliveryAddressAsync =
  (customerDeliveryAddressUUID: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(fetchSupplierDeliveryAddressProgress());
        const res = await api.get(
          `/customer/get-customer-delivery-address?customer_DELILVERY_ADDRESS_uuid=${customerDeliveryAddressUUID}`,
        );
        const data: ISupplierDeliveryAddress[] = res.data.data;
        if (data.length > 0) {
          dispatch(fetchSupplierDeliveryAddressSuccess(data[0]));
        } else {
          dispatch(
            fetchSupplierDeliveryAddressFailed("Oops! We couldn't find any records."),
          );
        }
      } catch (err: any) {
        dispatch(fetchSupplierDeliveryAddressFailed("Something went to be wrong!"));
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

export const CLEAR_CUSTOMER_DELIVERY_ADDRESS = "CLEAR_CUSTOMER_DELIVERY_ADDRESS";
export const CLEAR_CUSTOMER_DELILVERY_ADDRESS_STATE = "CLEAR_CUSTOMER_DELILVERY_ADDRESS_STATE";

export const clearSupplierDeliveryAddress = () => action(CLEAR_CUSTOMER_DELIVERY_ADDRESS);
export const clearSupplierDeliveryAddressState = () =>
  action(CLEAR_CUSTOMER_DELILVERY_ADDRESS_STATE);
