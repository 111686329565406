import { Add, Delete, Visibility } from "@mui/icons-material";
import {
	Box,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from "@mui/material";
import produce from "immer";
import React, { useState } from "react";
import { defaultOrderItem, IOrderItem } from "../../../redux/orders";
import { ProductAutoSearch } from "../../../components/AutoCompleteSearches/ProductAutoSearch";
import { CustomTextField } from "../../../components/formsComponents";
import { IProduct } from "../../../redux/products";
import { PreviewImage } from "../../../components/PreviewImage/PreviewImage";
import { ViewSingleOrderItemDialog } from "../dialogs/ViewSingleOrderItemDialog";
import { ProductAutoSearchDialogField } from "../../products/AutoSearch/ProductAutoSearchDialogField";
import { ProductAutoSearchPanel } from "../../products/AutoSearch/ProductAutoSearchPanel";

interface IProps {
	uuid: string;
	disabled?: boolean;
	data: IOrderItem[];
	supplierName: string;
	onChange: (data: any) => void;
}
export const SupplierOrderItemsTable: React.FC<IProps> = ({
	uuid,
	data,
	disabled,
	supplierName,
	onChange,
}) => {
	const [openProductDialog, setOpenProductDialog] = React.useState<
		number | null
	>(null);

	const [preViewSingleProduct, setPreViewSingleProduct] =
		useState<IOrderItem | null>(null);
	const handleProductChange = (index: number, key: string, value: any) => {
		const newValue = produce(data, (draftValues) => {
			(draftValues[index][key as keyof IOrderItem] as any) = value;
			// (draftValues[index]["total_price"] as any) = draftValues[index]["quantity"] * draftValues[index]["price"];
		});
		onChange(newValue);
	};

	const handleAdd = (index: number) => {
		const newValues = produce(data, (draftValues) => {
			draftValues.splice(index + 1, 0, defaultOrderItem);
		});
		onChange(newValues);
	};

	const handleDelete = (index: number) => {
		const newValues = produce(data, (draftValues) => {
			draftValues.splice(index, 1);
		});
		onChange(newValues);
	};

	const handleProductItemChange = (index: number, value: IProduct) => {
		const newValue = produce(data, (draftValues) => {
			draftValues[index]["orders_uuid"] = uuid;
			// draftValues[index]["order_item_uuid"] = null;
			draftValues[index]["product_uuid"] = value.product_uuid;
			draftValues[index]["product_name"] = value.product_name;
			draftValues[index]["supplier_name"] = supplierName;
			draftValues[index]["product_vpn"] = value.product_vpn;
			draftValues[index]["primary_image_url"] = value.preview__url;
			draftValues[index]["style_code"] = value.style_code;
			draftValues[index]["upc_ean"] = value.upc_ean;
			draftValues[index]["brand"] = value.brand;
			draftValues[index]["description"] = value.description;
			draftValues[index]["category"] = value.category;
			draftValues[index]["taxonomy1"] = value.taxonomy1;
			draftValues[index]["taxonomy2"] = value.taxonomy2;
			draftValues[index]["hs_code"] = value.hs_code;
			draftValues[index]["color_code"] = value.color_code;
			draftValues[index]["color"] = value.color;
			draftValues[index]["size"] = value.size;
			draftValues[index]["quantity_ordered"] = value.quantity_ordered;
			draftValues[index]["season"] = value.season;
			// draftValues[index]['size_uda'] = value.size_uda;
			// draftValues[index]['line'] = value.line;
			// draftValues[index]['sub_line'] = value.sub_line;
			// draftValues[index]['recurrence'] = value.recurrence;
			// draftValues[index]['attribute_gender'] = value.attribute_gender;
			// draftValues[index]['attribute_boy_girl'] = value.attribute_boy_girl;
			// draftValues[index]['attribute_theme'] = value.attribute_theme;
			// draftValues[index]['attribute_material_composition'] = value.attribute_material_composition;
			// draftValues[index]['made_of'] = value.made_of;
			// draftValues[index]['attribute_miscellaneous'] = value.attribute_miscellaneous;
			// draftValues[index]['attribute_color'] = value.attribute_color;
			// draftValues[index]['apparel_style'] = value.apparel_style;
			// draftValues[index]['item_attribute_shape'] = value.item_attribute_shape;
			// draftValues[index]['supplier_class'] = value.supplier_class;
			// draftValues[index]['supplier_subclass'] = value.supplier_subclass;
			// draftValues[index]["usage_specificity"] = value.usage_specificity;
			// draftValues[index]['item_attribute_launch_date'] = value.item_attribute_launch_date;
			// draftValues[index]['country_of_manufacture'] = value.country_of_manufacture;
			// draftValues[index]['country_of_shipment'] = value.country_of_shipment;
			draftValues[index]["purchase_price"] = Number(value.purchase_price);
			draftValues[index]["retail_price"] = Number(value.retail_price);
			draftValues[index]["currency"] = value.currency;
			// draftValues[index]["price"] = Number(value.retail_price);
		});

		onChange(newValue);
	};

	const handleProductSelect = (index: number, value: IProduct) => {
		const newItem = { ...defaultOrderItem };
		newItem["orders_uuid"] = uuid;
		newItem["product_uuid"] = value.product_uuid;
		newItem["product_name"] = value.product_name;
		newItem["supplier_name"] = supplierName;
		newItem["product_vpn"] = value.product_vpn;
		newItem["primary_image_url"] = value.preview__url;
		newItem["style_code"] = value.style_code;
		newItem["upc_ean"] = value.upc_ean;
		newItem["brand"] = value.brand;
		newItem["description"] = value.description;
		newItem["category"] = value.category;
		newItem["taxonomy1"] = value.taxonomy1;
		newItem["taxonomy2"] = value.taxonomy2;
		newItem["hs_code"] = value.hs_code;
		newItem["color_code"] = value.color_code;
		newItem["color"] = value.color;
		newItem["size"] = value.size;
		newItem["quantity_ordered"] = value.quantity_ordered;
		newItem["season"] = value.season;
		// newItem["usage_specificity"] = value.usage_specificity;
		newItem["purchase_price"] = Number(value.purchase_price);
		newItem["retail_price"] = Number(value.retail_price);
		newItem["currency"] = value.currency;

		const newValues = produce(data, (draftValues) => {
			draftValues.splice(index + 1, 0, newItem);
		});
		onChange(newValues);
		setOpenProductDialog(null);
	};

	return (
		<TableContainer component={Paper}>
			<Table aria-label="Enquiry Checklist">
				<TableHead
					sx={(theme) => ({
						backgroundColor: theme.palette.grey[200],
					})}
				>
					<TableRow>
						<TableCell sx={{ width: 250 }}>Name</TableCell>
						<TableCell>Quantity</TableCell>
						<TableCell>Price</TableCell>
						<TableCell>Total</TableCell>
						{/* <TableCell>Discrepancy Quantity</TableCell> */}
						<TableCell>Preview</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{data?.map((item, index) => {
						return (
							<TableRow key={index}>
								<TableCell
									width={250}
									height={100}
									tabIndex={-1}
								>
									<Typography
										component="span"
										sx={{
											borderRadius: 1,
										}}
										variant="body1"
									>
										{item.product_name}
									</Typography>
									<Box
										mt={1}
										onClick={() => setPreViewSingleProduct(item)}
										sx={{
											maxHeight: 100,
											maxWidth: 100,
											overflow: "hidden",
											width: "100%",
											height: "100%",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											objectFit: "contain",
										}}
									>
										<PreviewImage imageUrl={item.preview_url as string} />
									</Box>
								</TableCell>
								<TableCell>
									<CustomTextField
										name="quantity_ordered"
										type="number"
										disabled={true}
										tabIndex={0} // Make navigable by keyboard
										value={item.quantity_ordered}
										onChange={(e) =>
											handleProductChange(
												index,
												"quantity_ordered",
												e.target.value
											)
										}
									/>
								</TableCell>
								<TableCell>
									<CustomTextField
										name="price"
										type="number"
										disabled={true}
										tabIndex={0} // Make navigable by keyboard
										value={item.purchase_price}
										onChange={(e) =>
											handleProductChange(
												index,
												"purchase_price",
												e.target.value
											)
										}
									/>
								</TableCell>
								<TableCell tabIndex={-1}>
									{" "}
									{/* Make non-navigable */}
									{Number(item.quantity_ordered) * Number(item.purchase_price)}
								</TableCell>
								{/* <TableCell>
									<CustomTextField
										name="quantity_ordered"
										type="number"
										value={item.quantity_ordered}
										disabled={disabled}
										tabIndex={0} // Make navigable by keyboard
										onChange={(e) =>
											handleProductChange(
												index,
												"quantity_ordered",
												e.target.value
											)
										}
									/>
								</TableCell> */}
								<TableCell tabIndex={-1}>
									{" "}
									{/* Make non-navigable */}
									<Tooltip title="View Item">
										<IconButton
											disabled={disabled}
											onClick={() => setPreViewSingleProduct(item)}
										>
											<Visibility />
										</IconButton>
									</Tooltip>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>

			{preViewSingleProduct && (
				<ViewSingleOrderItemDialog
					open={true}
					productInfo={preViewSingleProduct}
					onClose={() => setPreViewSingleProduct(null)}
				/>
			)}

			{openProductDialog && (
				<ProductAutoSearchPanel
					open={true}
					onClose={() => setOpenProductDialog(null)}
					onSelect={(data) => handleProductSelect(openProductDialog, data)}
				/>
			)}
		</TableContainer>
	);
};
