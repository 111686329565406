import { LoadState } from "../../constants/enums"
import { IProductDocument, IProductDocumentState } from "./product-documents.types"



export const defaultProductDocument: IProductDocument = {
    product_documents_uuid: "",
    name: "",
    file_path: "",
    file_type: "",
    extracted_images: [],

    created_by_uuid: "",
    created_by_name: "",
    modified_by_uuid: "",
    modified_by_name: "",
    status: "ACTIVE",
}

export const defaultProductDocumentState: IProductDocumentState = {
    product_documents_list: {
        loading: LoadState.NotLoaded,
        data: [],
        total_count: 0,
        error: null
    },
    single_product_document: {
        loading: LoadState.NotLoaded,
        data: defaultProductDocument,
        error: null
    },
    document_temporary_products: {
        loading: LoadState.NotLoaded,
        data: [],
        total_count: 0,
        error: null
    }
}