import { api } from "../../../api/api";
import {
  getFileExtensionFromUrl,
} from "./getFileNameFromUrl";

export const previewFile = async (fileName: string | null) => {
  if (fileName) {
    const res = await api.post(
      "/general/download-files",
      {
        type: "",
        keys: [fileName],
      },
      {
        responseType: "arraybuffer",
      }
    );
    const blob = new Blob([res.data], {
      type: "application/" + getFileExtensionFromUrl(fileName),
    });
    const url = window.URL.createObjectURL(blob);
    window.open(url, "_blank");
  }
  return null;
};
