/* eslint-disable react-hooks/exhaustive-deps */
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
	Autocomplete,
	Box,
	CircularProgress,
	Stack,
	Typography,
} from "@mui/material";
import { debounce } from "lodash";
import React, { SyntheticEvent } from "react";
import { defaultSupplier, ISupplier } from "../../../../redux/supplier";
import { api } from "../../../../api/api";
import { removeDuplicateItems } from "../../../../helpers/removeDuplicateItems";
import {
	CustomFormLabel,
	CustomTextField,
} from "../../../../components/formsComponents";
import { SupplierRightPanel } from "../RightPanel/SupplierRightPanel";

export interface ISupplierAutoSearchProps {
	uniqueKey: string;
	label: string;
	name?: string;
	hideAddButton?: boolean;
	value: {
		supplier_uuid: string;
		supplier_name: string;
	};
	onSelect: (data: ISupplier) => void;
	disabled?: boolean;
	error?: boolean;
	helperText?: string;
}

const INITIAL_STATE: ISupplier = defaultSupplier;

export const SupplierAutoSearch: React.FC<ISupplierAutoSearchProps> = (
	props
) => {
	const {
		uniqueKey,
		value,
		onSelect,
		label,
		hideAddButton,
		disabled,
		error,
		helperText,
		name,
	} = props;
	const [options, setOptions] = React.useState<readonly ISupplier[]>([]);
	const [loading, setLoading] = React.useState(false);
	const [search, setSearchText] = React.useState<string>("");
	const [openRightPanel, setOpenRightPanel] = React.useState<boolean>(false);

	const fetchSuggestion = async (enteredValue: string) => {
		setLoading(true);
		try {
			let searchQuery = "";
			if (enteredValue.length > 0) {
				searchQuery += `columns=supplier_name,legal_entity&value=${enteredValue}&`;
			}
			const res = await api.get(
				`/supplier/get-supplier?${searchQuery}pageNo=1&itemPerPage=10`
			);
			const finalData: ISupplier[] = res.data.data;
			if (value?.supplier_uuid?.length > 0 && search.length === 0) {
				const option: ISupplier = {
					...INITIAL_STATE,
					supplier_uuid: value.supplier_uuid,
					legal_entity: value.supplier_name,
				};
				finalData.push(option);
			}
			setOptions(finalData);
		} catch (error) {
			console.error("Error fetching data:", error);
		} finally {
			setLoading(false);
		}
	};
	const debounceFn = React.useCallback(debounce(fetchSuggestion, 800), []);

	const getOptionLabel = (option: ISupplier | string) => {
		if (typeof option === "string") return option || "";
		return value.supplier_name === option?.supplier_name
			? option?.supplier_name
			: `${option?.supplier_name} ${
					option?.legal_entity ? `( ${option.legal_entity} )` : ""
			  }`;
	};

	const getValue = () => {
		return (
			options.find((option) => option.supplier_uuid === value.supplier_uuid) ||
			null
		);
	};

	const handleToggle = () => {
		setOpenRightPanel(!openRightPanel);
	};

	const handleComplete = (customer: ISupplier) => {
		setOptions([customer, ...options]);
		onSelect(customer);
		handleToggle();
	};

	const handleDropDownOnFocus = () => {
		debounceFn("");
	};

	React.useEffect(() => {
		if (search && search.length > 0 && search !== value.supplier_name) {
			debounceFn(search);
		}
	}, [search]);

	React.useEffect(() => {
		if (value && value.supplier_uuid?.length > 0) {
			const option: ISupplier = {
				...INITIAL_STATE,
				supplier_uuid: value.supplier_uuid,
				supplier_name: value.supplier_name,
			};
			setOptions(
				removeDuplicateItems(
					[option, ...options],
					"supplier_uuid"
				) as ISupplier[]
			);
		} else {
			setOptions([]);
		}
	}, [value]);

	return (
		<>
			{label && (
				<Box
					display="flex"
					justifyContent={"flex-start"}
					alignItems="center"
				>
					<CustomFormLabel display={"block"}>{` ${label}  `}</CustomFormLabel>
					{!hideAddButton &&
						search.length > 0 &&
						options.length === 0 &&
						!loading && (
							<Box
								sx={{
									paddingLeft: 1,
									display: "flex",
									alignItems: "center",
									justifyContent: "flex-start",
									marginTop: 1,
									cursor: "pointer",
								}}
								onClick={handleToggle}
							>
								<AddCircleIcon
									fontSize="small"
									color="primary"
								/>
								<Typography
									variant="h6"
									color="primary"
									marginLeft={0.5}
									marginTop={0.3}
									fontWeight={500}
								>
									Add Supplier
								</Typography>
							</Box>
						)}
				</Box>
			)}
			<Stack
				direction={"row"}
				justifyContent={"space-between"}
				spacing={1}
			>
				<Autocomplete
					id={uniqueKey}
					fullWidth
					freeSolo
					disabled={disabled}
					sx={{
						".MuiOutlinedInput-root": {
							paddingTop: "2px",
							paddingBottom: "2px",
							fontSize: "0.8rem",
							color: "rgb(38, 38, 38)",
							width: "100%",
							backgroundColor: disabled ? "#f8f9fb" : "inherit",
						},
					}}
					getOptionLabel={getOptionLabel}
					isOptionEqualToValue={(option, value) =>
						typeof option !== "string" && typeof value !== "string"
							? option.supplier_name === value.supplier_name
							: option === value
					}
					filterOptions={(x) => x}
					options={options}
					autoComplete
					includeInputInList
					value={getValue()}
					noOptionsText="No Customers"
					onFocus={handleDropDownOnFocus}
					onChange={(
						event: SyntheticEvent<Element, Event>,
						newValue: string | ISupplier | null
					) => {
						if (newValue && typeof newValue !== "string") {
							onSelect(newValue);
						}
					}}
					onInputChange={(event, newInputValue) => {
						if ((event && event.type === "change") || !newInputValue) {
							setSearchText(newInputValue);
						}
					}}
					renderInput={(params) => (
						<CustomTextField
							name={name}
							{...params}
							fullWidth
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<React.Fragment>
										{loading && (
											<CircularProgress
												color="inherit"
												size={20}
											/>
										)}
										{params.InputProps.endAdornment}
									</React.Fragment>
								),
							}}
							disabled={disabled}
							error={error ? true : false}
							helperText={helperText}
						/>
					)}
				/>
				{/* <Box>
          <Tooltip title="Create Customer">
            <Fab
              size="small"
              color="primary"
              aria-label="add"
              onClick={handleToggle}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        </Box> */}
			</Stack>
			{openRightPanel && (
				<SupplierRightPanel
					open={openRightPanel}
					customerName={search}
					onClose={handleToggle}
					onComplete={handleComplete}
				/>
			)}
		</>
	);
};
