import { Box, Divider, Drawer } from "@mui/material";
import React from "react";
import { CustomTypography } from "../formsComponents";
import { IRightPanelProps } from "./interfaces/IRightPanelProps";

export const RightPanel: React.FC<IRightPanelProps> = (props) => {
	const {
		open,
		heading,
		isWrappedWithForm = false,
		width = "35%",
		onFormSubmit,
		subHeading,
		actionButtons,
		onClose,
	} = props;

	const content = () => {
		return (
			<Box
				zIndex={1900}
				display="flex"
				flexDirection={"column"}
				justifyContent="space-between"
				height={"100%"}
			>
				<Box flex={1}>
					<Box sx={{ padding: "0px 20px" }}>
						<CustomTypography
							variant="h2"
							fontWeight={"bold"}
						>
							{heading}
						</CustomTypography>
						{subHeading && (
							<CustomTypography variant="body1">{subHeading}</CustomTypography>
						)}
					</Box>
				</Box>
				<Box
					flex={10}
					sx={{ marginTop: 5, overflowY: "auto", padding: "0px 20px" }}
				>
					{props.children}
				</Box>
				{actionButtons && (
					<Box
						flex={1}
						sx={{ padding: "0px 20px" }}
					>
						<Divider sx={{ marginBottom: 3 }} />
						{actionButtons()}
					</Box>
				)}
			</Box>
		);
	};

	const wrappedWithForm = () => {
		if (isWrappedWithForm && onFormSubmit) {
			return (
				<form
					onSubmit={onFormSubmit}
					style={{ height: "100%" }}
				>
					{content()}
				</form>
			);
		}
		return content();
	};

	return (
		<Drawer
			anchor="right"
			open={open}
			PaperProps={{
				sx: {
					zIndex: 1900,
					width: {
						xs: "100%",
						md: "80%",
						lg: width,
					},
					padding: "40px 0px",
					paddingBottom: "1%",
					height: "100vh",
				},
			}}
			onClose={onClose}
		>
			{wrappedWithForm()}
		</Drawer>
	);
};
