import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { IQueryParams } from "../../common/common.types";
import { getSearchQuery } from "../../common/helpers";
import { ISupplierContact } from "./supplier-contact.types";

export const FETCH_SUPPLIER_CONTACTS_LIST_PROGRESS = "FETCH_SUPPLIER_CONTACTS_LIST_PROGRESS";
export const FETCH_SUPPLIER_CONTACTS_LIST_SUCCESS = "FETCH_SUPPLIER_CONTACTS_LIST_SUCCESS";
export const FETCH_SUPPLIER_CONTACTS_LIST_FAILED = "FETCH_SUPPLIER_CONTACTS_LIST_FAILED";

export const fetchSupplierContactListProgress = () =>
  action(FETCH_SUPPLIER_CONTACTS_LIST_PROGRESS);
export const fetchSupplierContactListSuccess = (
  data: ISupplierContact[],
  totalRecords: number,
) => action(FETCH_SUPPLIER_CONTACTS_LIST_SUCCESS, { data, totalRecords });
export const fetchSupplierContactListFailed = () => action(FETCH_SUPPLIER_CONTACTS_LIST_FAILED);

export const fetchSupplierContactListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        const searchQuery = getSearchQuery(queryParams);
        dispatch(fetchSupplierContactListProgress());

        const res = await api.get(`/supplier/get-contact${searchQuery}`);
        const data: ISupplierContact[] = res.data.data;

        const totalRecords = res.data.totalRecords;

        dispatch(fetchSupplierContactListSuccess(data, totalRecords));
      } catch (err: any) {
        dispatch(fetchSupplierContactListFailed());
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };



export const FETCH_SINGLE_SUPPLIER_CONTACT_LIST_PROGRESS = "FETCH_SINGLE_SUPPLIER_CONTACT_LIST_PROGRESS";
export const FETCH_SINGLE_SUPPLIER_CONTACT_LIST_SUCCESS = "FETCH_SINGLE_SUPPLIER_CONTACT_LIST_SUCCESS";
export const FETCH_SINGLE_SUPPLIER_CONTACT_LIST_FAILED = "FETCH_SINGLE_SUPPLIER_CONTACT_LIST_FAILED";

export const fetchSingleSupplierContactsListProgress = () =>
  action(FETCH_SINGLE_SUPPLIER_CONTACT_LIST_PROGRESS);
export const fetchSingleSupplierContactsListSuccess = (
  data: ISupplierContact[],
  totalRecords: number,
) => action(FETCH_SINGLE_SUPPLIER_CONTACT_LIST_SUCCESS, { data, totalRecords });
export const fetchSingleSupplierContactsListFailed = () => action(FETCH_SINGLE_SUPPLIER_CONTACT_LIST_FAILED);

export const fetchSingleSupplierContactsListAsync =
  (queryParams: IQueryParams, supplierUUID?: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        const searchQuery = getSearchQuery(queryParams);
        dispatch(fetchSingleSupplierContactsListProgress());
        const res = await api.get(`/supplier/get-contact${searchQuery}&supplier_uuid=${supplierUUID}`);
        const data: ISupplierContact[] = res.data.data;

        const totalRecords = res.data.totalRecords;

        dispatch(fetchSingleSupplierContactsListSuccess(data, totalRecords));
      } catch (err: any) {
        dispatch(fetchSingleSupplierContactsListFailed());
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };


export const upsertSingleContactAsync =
  (
    data: ISupplierContact,
    onCallback: (isSuccess: boolean, contact?: ISupplierContact) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(saveLoaderProgress());
        const { create_ts, insert_ts, ...rest } = data;
        const res = await api.post("/supplier/upsert-contact", rest);

        onCallback(true, res.data.data);
        dispatch(
          showMessage({
            type: "success",
            message: "Contact saved successfully!",
            displayAs: "snackbar",
          }),
        );
      } catch (err: any) {
        onCallback(false);
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      } finally {
        dispatch(saveLoaderCompleted());
      }
    };

export const FETCH_SUPPLIER_CONTACT_PROGRESS = "FETCH_SUPPLIER_CONTACT_PROGRESS";
export const FETCH_SUPPLIER_CONTACT_SUCCESS = "FETCH_SUPPLIER_CONTACT_SUCCESS";
export const FETCH_SUPPLIER_CONTACT_FAILED = "FETCH_SUPPLIER_CONTACT_FAILED";

export const fetchContactProgress = () => action(FETCH_SUPPLIER_CONTACT_PROGRESS);
export const fetchSupplierContactuccess = (data: ISupplierContact) =>
  action(FETCH_SUPPLIER_CONTACT_SUCCESS, { data });
export const fetchContactFailed = (errorMessage: string) =>
  action(FETCH_SUPPLIER_CONTACT_FAILED, { errorMessage });

export const fetchSingleContactAsync =
  (contactId: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(fetchContactProgress());
        const res = await api.get(
          `/supplier/get-contact?contact_uuid=${contactId}&pageNo=1&itemPerPage=1`,
        );
        const data: ISupplierContact[] = res.data.data;
        if (data.length > 0) {
          dispatch(fetchSupplierContactuccess(data[0]));
        } else {
          dispatch(fetchContactFailed("Oops! We couldn't find any records."));
        }
      } catch (err: any) {
        dispatch(fetchContactFailed("Something went to be wrong!"));
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

export const CLEAR_SINGLE_SUPPLIER_CONTACT = "CLEAR_SINGLE_SUPPLIER_CONTACT";
export const CLEAR_SUPPLIER_CONTACT_LIST = "CLEAR_SUPPLIER_CONTACT_LIST";
export const clearSingleSupplierContact = () => action(CLEAR_SINGLE_SUPPLIER_CONTACT);
export const clearContactList = () => action(CLEAR_SUPPLIER_CONTACT_LIST);
