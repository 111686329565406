import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { ISupplier, ISupplierState } from "./supplier.types";

export const FETCH_SUPPLIER_LIST_PROGRESS = "FETCH_SUPPLIER_LIST_PROGRESS";
export const FETCH_SUPPLIER_LIST_SUCCESS = "FETCH_SUPPLIER_LIST_SUCCESS";
export const FETCH_SUPPLIER_LIST_FAILED = "FETCH_SUPPLIER_LIST_FAILED";

export const fetchSupplierListProgress = () =>
  action(FETCH_SUPPLIER_LIST_PROGRESS);

export const fetchSupplierListSuccess = (
  data: ISupplierState["supplier_list"]["data"],
  totalRecords: number,
) => action(FETCH_SUPPLIER_LIST_SUCCESS, { data, totalRecords });
export const fetchSupplierListFailed = () => action(FETCH_SUPPLIER_LIST_FAILED);

export const fetchSupplierListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchSupplierListProgress());
      const res = await api.get(`/supplier/get-supplier${searchQuery}`);
      const data: ISupplierState["supplier_list"]["data"] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchSupplierListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchSupplierListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_SUPPLIER_PROGRESS = "FETCH_SUPPLIER_PROGRESS";
export const FETCH_SUPPLIER_SUCCESS = "FETCH_SUPPLIER_SUCCESS";
export const FETCH_SUPPLIER_FAILED = "FETCH_SUPPLIER_FAILED";

export const fetchSupplierProgress = () => action(FETCH_SUPPLIER_PROGRESS);
export const fetchSupplierSuccess = (data: ISupplier) =>
  action(FETCH_SUPPLIER_SUCCESS, { data });
export const fetchSupplierFailed = (errorMessage: string) =>
  action(FETCH_SUPPLIER_FAILED, { errorMessage });

export const fetchSingleSupplierAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSupplierProgress());
      const res = await api.get(`/supplier/get-supplier?supplier_uuid=${uuid}&pageNo=1&itemPerPage=11`);
      const data: ISupplier[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchSupplierSuccess(data[0]));
      } else {
        dispatch(fetchSupplierFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchSupplierFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertSingleSupplierAsync =
  (
    data: ISupplier,
    onCallback: (isSuccess: boolean, customer?: ISupplier) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const {
        created_by_uuid,
        create_ts,
        insert_ts,
        ...payload
      } = data;
      dispatch(saveLoaderProgress());
      const res = await api.post("/supplier/upsert-supplier", payload);
      let message = "Supplier saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_SINGLE_SUPPLIER = "CLEAR_SINGLE_SUPPLIER";
export const CLEAR_SUPPLIER_STATE = "CLEAR_SUPPLIER_STATE";
export const clearSingleSupplier = () => action(CLEAR_SINGLE_SUPPLIER);
export const clearSupplierState = () => action(CLEAR_SUPPLIER_STATE);
