import React, { useEffect, useState } from 'react'
import { useDispatchWrapper } from '../../../hooks';
import { extractProductsFromExcelFileAsync, uploadProductDocumentAsync } from '../../../redux/file-upload-with-ai/file-upload-with-ai.actions';
import { PageContainer } from '../../../components/container/PageContainer';
import { Breadcrumb } from '../../../layout/fullLayout/breadcrumb/Breadcrumb';
import { StandardCard } from '../../../components/Cards';
import { Box, Button, Grid, Stack } from '@mui/material';
import { ControlledCustomSelect, CustomFormLabel, CustomTextField } from '../../../components/formsComponents';
import { ProductsTemplateAutoSearch } from '../../../components/AutoCompleteSearches/ProductsTemplateAutoSearch';
import { PreviewExcelProductsTable } from './PreviewExcelProductsTable';
import { useFormik } from 'formik';
import { FileUpload } from '../../../components/FileUpload/FileUpload';
import { defaultAiPdfUpload } from '../../../redux/file-upload-with-ai/file-upload-with-ai.state';

interface IExcelFileProductsScreenProps {
    onSaveSuccess: () => void
}
export const ExcelFileProductsScreen: React.FC<IExcelFileProductsScreenProps> = ({
    onSaveSuccess
}) => {
    const dispatch = useDispatchWrapper();
    const [documentUUID, setDocumentUUID] = useState<string | null>(null)
    // useEffect(() => {
    //     if (uuid && templateUUID) {
    //         dispatch(extractProductsFromExcelFileAsync(uuid, templateUUID, (isSuccess, data) => {
    //             if (isSuccess && data) {

    //             }
    //         }))
    //     }
    // }, [uuid, templateUUID])



    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        setValues,
        setFieldValue,
        isValid,
        resetForm,
    } = useFormik({
        initialValues: {
            document_name: "",
            template_uuid: "",
            file: null
        },
        validate: (values) => {
            const errors: any = {};
            if (!values.document_name) {
                errors.document_name = "*This is required field"
            }
            if (!values.template_uuid) {
                errors.template_uuid = "*This is required field"
            }

            return errors;
        },
        onSubmit: (values) => {
            dispatch(
                uploadProductDocumentAsync(
                    {
                        ...defaultAiPdfUpload,
                        file_type: "EXCEL",
                        name: values.document_name || (values.file as any)?.name
                    },
                    (isSuccess, data) => {
                        if (isSuccess && data) {
                            dispatch(extractProductsFromExcelFileAsync({
                                product_documents_uuid: data.product_documents_uuid || "",
                                template_uuid: values.template_uuid || "",
                            }, (isSuccess, excelOptions) => {
                                if (isSuccess && excelOptions) {
                                    setDocumentUUID(data.product_documents_uuid)
                                }
                            }))

                        }
                    },
                ),
            );
        },
    });



    return (
        <PageContainer
            title={"Template Products"}
            description="this is inner page"
        >
            <StandardCard  heading={`Upload New Line Sheet`}>
                <Box component={"form"} onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={4} md={4}>
                            <CustomFormLabel>Document Name</CustomFormLabel>
                            <CustomTextField
                                id='document_name'
                                name="document_name"
                                variant="outlined"
                                size="small"
                                fullWidth
                                value={values.document_name}
                                onChange={handleChange}
                                error={errors.document_name ? true : false}
                                helperText={errors.document_name}
                            />
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <ProductsTemplateAutoSearch
                                label='Choose Template'
                                value={values.template_uuid || ""}
                                onSelect={(templateData) => {
                                    setFieldValue("template_uuid", templateData.template_uuid)
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <CustomFormLabel>File Upload</CustomFormLabel>
                            <FileUpload
                                value={null}
                                onChange={(file) => setFieldValue("file", file)}
                                uploadType={"EXCEL"}
                            />
                        </Grid>
                    </Grid>
                    <Stack
                        mt={2}
                        direction={"row"}
                        justifyContent={"flex-end"}
                        alignItems={"flex-end"}
                    >
                        <Button
                            variant="contained"
                            type={"submit"}
                            disabled={!values.file}
                        >
                            Upload
                        </Button>
                    </Stack>
                </Box>
            </StandardCard>


            {documentUUID && <PreviewExcelProductsTable documentUUID={documentUUID} onSaveSuccess={onSaveSuccess} />}

        </PageContainer>
    )
}
