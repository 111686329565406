import { CustomizerActions } from ".";

import {
  THEME_COLOR,
  NAVBAR_BG,
  SIDEBAR_BG,
  DIRECTION,
  DARK_THEME,
  SIDEBAR_OPEN,
  TOGGLE_SIDEBAR,
} from "../constants";
import { initialStoreState } from "../initialStoreState";

export interface ICustomizerState {
  activeDir: string;
  activeNavbarBg: string;
  activeSidebarBg: string;
  activeMode: string;
  activeTheme: string;
  SidebarWidth: number;
  sidebarOpen: boolean;
}

export const customizerReducer = (
  state: ICustomizerState = initialStoreState["customizerReducer"],
  action: CustomizerActions,
) => {
  switch (action.type) {
    case NAVBAR_BG:
      return {
        ...state,
        activeNavbarBg: action.payload,
      };
    case DARK_THEME:
      return {
        ...state,
        activeMode: action.payload,
      };
    case SIDEBAR_BG:
      return {
        ...state,
        activeSidebarBg: action.payload,
      };
    case THEME_COLOR:
      return {
        ...state,
        activeTheme: action.payload,
      };
    case DIRECTION:
      return {
        ...state,
        activeDir: action.payload,
      };
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarOpen: action.payload,
      };

    default:
      return state;
  }
};
