import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import {
    CLEAR_PRODUCT_DOCUMENTS_STATE,
    FETCH_PRODUCT_DOCUMENT_LIST_FAILED,
    FETCH_PRODUCT_DOCUMENT_LIST_PROGRESS,
    FETCH_PRODUCT_DOCUMENT_LIST_SUCCESS,
    FETCH_PRODUCT_VALIDATION_LIST_FAILED,
    FETCH_PRODUCT_VALIDATION_LIST_PROGRESS,
    FETCH_PRODUCT_VALIDATION_LIST_SUCCESS,
} from "./product-documents.actions";
import { defaultProductDocumentState, ProductDocumentActions } from ".";

export const productDocumentReducer = (
    state: IStoreState["productDocuments"] = defaultProductDocumentState,
    action: ProductDocumentActions,
) => {
    switch (action.type) {
        case FETCH_PRODUCT_DOCUMENT_LIST_PROGRESS: {
            const newState = produce(state, (draftState) => {
                draftState.product_documents_list.loading = LoadState.InProgress;
                draftState.product_documents_list.data = [];
                draftState.product_documents_list.total_count = 0;
            });
            return newState;
        }
        case FETCH_PRODUCT_DOCUMENT_LIST_SUCCESS: {
            const { data, totalRecords } = action.payload;
            const newState = produce(state, (draftState) => {
                draftState.product_documents_list.loading = LoadState.Loaded;
                draftState.product_documents_list.data = data;
                draftState.product_documents_list.total_count = totalRecords;
            });
            return newState;
        }
        case FETCH_PRODUCT_DOCUMENT_LIST_FAILED: {
            const newState = produce(state, (draftState) => {
                draftState.product_documents_list.loading = LoadState.Failed;
            });
            return newState;
        }
        case CLEAR_PRODUCT_DOCUMENTS_STATE: {
            return defaultProductDocumentState;
        }




        case FETCH_PRODUCT_VALIDATION_LIST_PROGRESS: {
            const newState = produce(state, (draftState) => {
                draftState.document_temporary_products.loading = LoadState.InProgress;
                draftState.document_temporary_products.data = [];
                draftState.document_temporary_products.total_count = 0;
            });
            return newState;
        }
        case FETCH_PRODUCT_VALIDATION_LIST_SUCCESS: {
            const { data, totalRecords } = action.payload;
            const newState = produce(state, (draftState) => {
                draftState.document_temporary_products.loading = LoadState.Loaded;
                draftState.document_temporary_products.data = data;
                draftState.document_temporary_products.total_count = totalRecords;
            });
            return newState;
        }
        case FETCH_PRODUCT_VALIDATION_LIST_FAILED: {
            const newState = produce(state, (draftState) => {
                draftState.document_temporary_products.loading = LoadState.Failed;
            });
            return newState;
        }
        default: {
            return state;
        }
    }
};
