import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { initialSecurityState } from "./security.types";
import { SecurityActions } from ".";
import {
  CLEAR_SECURITY_APPROVAL,
  CLEAR_SECURITY_GROUP,
  FETCH_ROLES_LIST_FAILED,
  FETCH_ROLES_LIST_PROGRESS,
  FETCH_ROLES_LIST_SUCCESS,
  FETCH_SECURITY_APPROVAL_FAILED,
  FETCH_SECURITY_APPROVAL_LIST_FAILED,
  FETCH_SECURITY_APPROVAL_LIST_PROGRESS,
  FETCH_SECURITY_APPROVAL_LIST_SUCCESS,
  FETCH_SECURITY_APPROVAL_PROGRESS,
  FETCH_SECURITY_APPROVAL_SUCCESS,
  FETCH_SECURITY_GROUP_FAILED,
  FETCH_SECURITY_GROUP_PROGRESS,
  FETCH_SECURITY_GROUP_SUCCESS,
} from "./securityActions";
import {
  CLEAR_SECURITY_ROLE_GROUP_LIST,
  FETCH_SECURITY_ROLES_GROUP_LIST_FAILED,
  FETCH_SECURITY_ROLES_GROUP_LIST_PROGRESS,
  FETCH_SECURITY_ROLES_GROUP_LIST_SUCCESS,
} from "./role-group/role-group.actions";

export const securityReducer = (
  state: IStoreState["security"] = initialSecurityState,
  action: SecurityActions,
) => {
  switch (action.type) {
    case FETCH_SECURITY_GROUP_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.groups.loading = LoadState.InProgress;
        draftState.groups.group = initialSecurityState["groups"]["group"];
        draftState.groups.error = null;
      });
      return newState;
    }
    case FETCH_SECURITY_GROUP_SUCCESS: {
      const { data, role, roleGroup, status } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.groups.loading = LoadState.Loaded;
        draftState.groups.group = data;
        draftState.groups.roleName = role;
        draftState.groups.role_group = roleGroup;
        draftState.groups.status = status;
      });
      return newState;
    }

    case FETCH_SECURITY_GROUP_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.groups.loading = LoadState.Failed;
        draftState.groups.error = errorMessage ? errorMessage : null;
      });
      return newState;
    }

    case FETCH_ROLES_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.roles.loading = LoadState.InProgress;
        draftState.roles.list = [];
      });
      return newState;
    }
    case FETCH_ROLES_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.roles.loading = LoadState.Loaded;
        draftState.roles.list = list;
        draftState.roles.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_ROLES_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.roles.loading = LoadState.Failed;
        draftState.roles.list = [];
        draftState.roles.totalRecords = 0;
      });
      return newState;
    }

    case CLEAR_SECURITY_GROUP: {
      const newState = produce(state, (draftState) => {
        draftState.groups = initialSecurityState["groups"];
      });
      return newState;
    }

    case FETCH_SECURITY_APPROVAL_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.approval.loading = LoadState.InProgress;
        draftState.approval.list = [];
      });
      return newState;
    }
    case FETCH_SECURITY_APPROVAL_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.approval.loading = LoadState.Loaded;
        draftState.approval.list = data;
        draftState.approval.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_SECURITY_APPROVAL_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.approval.loading = LoadState.Failed;
        draftState.approval.list = [];
        draftState.approval.totalRecords = 0;
      });
      return newState;
    }

    case FETCH_SECURITY_APPROVAL_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.approval.approvalLoading = LoadState.InProgress;
        draftState.approval.approvalData =
          initialSecurityState["approval"]["approvalData"];
        draftState.approval.error = null;
      });
      return newState;
    }
    case FETCH_SECURITY_APPROVAL_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.approval.approvalLoading = LoadState.Loaded;
        draftState.approval.approvalData = data;
      });
      return newState;
    }
    case FETCH_SECURITY_APPROVAL_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.approval.approvalLoading = LoadState.Failed;
        draftState.approval.approvalData =
          initialSecurityState["approval"]["approvalData"];
        draftState.approval.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_SECURITY_APPROVAL: {
      const newState = produce(state, (draftState) => {
        draftState.approval = initialSecurityState["approval"];
      });
      return newState;
    }

    // ################################### Security Role Group #############################################
    case FETCH_SECURITY_ROLES_GROUP_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.roleGroups.loading = LoadState.InProgress;
        draftState.roleGroups.list = [];
      });
      return newState;
    }
    case FETCH_SECURITY_ROLES_GROUP_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.roleGroups.loading = LoadState.Loaded;
        draftState.roleGroups.list = list;
        draftState.roleGroups.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_SECURITY_ROLES_GROUP_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.roleGroups.loading = LoadState.Failed;
        draftState.roleGroups.list = [];
        draftState.roleGroups.totalRecords = 0;
      });
      return newState;
    }

    case CLEAR_SECURITY_ROLE_GROUP_LIST: {
      const newState = produce(state, (draftState) => {
        draftState.roleGroups = initialSecurityState["roleGroups"];
      });
      return newState;
    }

    default: {
      return state;
    }
  }
};
