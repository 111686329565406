/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { StandardCard } from "../../components/Cards";
import { CustomStatusSelector } from "../../components/Common/CustomStatusSelector";
import { PageContainer } from "../../components/container/PageContainer";
import { LabelCard } from "../../components/LabelCard/LabelCard";
import {
	ILocationResponsePayload,
	LocationAutoComplete,
} from "../../components/LocationAutoComplete/LocationAutoComplete";
import { TextFieldWithLabel } from "../../components/TextFieldWithLabel/TextFieldWithLabel";
import { useDispatchWrapper, useLoggedInUserInfo } from "../../hooks";
import { IStoreState } from "../../redux/initialStoreState";
import {
	clearSingleOrderSync,
	fetchSupplierOrderAsync,
	upsertExternalSupplierOrderDiscrepancies,
	upsertFileOrderDiscrepancies,
} from "../../redux/orders";
import { SupplierAutoSearch } from "../supplier/Suppliers/AutoSearch/SupplierAutoSearch";
import { APP_ORDERS_ROUTE } from "./Orders.constants";
import { ISupplier } from "../../redux/supplier";
import { displayPriceFormat } from "../../helpers/isNotNull";
import { handleApprovalAsync } from "../../redux/approval/quote";
import { APP_APPROVALS_ORDERS_ROUTE } from "../approval/Approvals.constants";
import { SupplierOrderItemsTable } from "./components/SupplierOrderItemsTable";
import { PageLoader } from "../../components/PageLoader/PageLoader";
import { APPLICATION_ROLES, LoadState } from "../../constants/enums";
import { CustomFormLabel } from "../../components/formsComponents";
import { useAuth } from "../../contexts/AuthProvider/AuthProvider";
import { FileUpload } from "../../components/FileUpload/FileUpload";
import { toggleSidebar } from "../../redux/customizer/action";
import { batch } from "react-redux";
import { SuccessDialog } from "../../components/Dialogs/SuccessDialog";
import { userInfo } from "os";

export const SupplierManageOrder = () => {
	const { uuid: singleOrderUUID } = useParams() as { uuid?: string };
	const loginedUserInfo = useLoggedInUserInfo();

	const [approvalLoading, setApprovalLoading] = React.useState(false);
	const [openRejectDialog, setOpenRejectDialog] =
		React.useState<boolean>(false);

	const dispatch = useDispatchWrapper();
	const navigate = useNavigate();
	const {
		user: { role_value },
	} = useAuth().userInfo;
	const [ocFile, setOcFile] = React.useState<File | null>(null);

	const { data: singleOrderDetails, loading } = useSelector(
		(storeState: IStoreState) => storeState.orders.single_order
	);
	const isSupplier = role_value === APPLICATION_ROLES.SUPPLIER;
	const [openSuccess, setOpenSuccess] = React.useState(false);

	const {
		values,
		errors,
		handleChange,
		handleSubmit,
		validateForm,
		setValues,
		setFieldValue,
	} = useFormik({
		initialValues: singleOrderDetails,
		validate: (values) => {
			const errors: any = {};
			// if (!values.company_name) {
			//   errors.company_name = "*Company name is required!";
			// }
			return errors;
		},
		onSubmit: async (values) => {
			if (values.orders_uuid && ocFile) {
				dispatch(
					upsertFileOrderDiscrepancies(
						values.orders_uuid,
						ocFile,
						(isSuccess) => {
							if (isSuccess) {
								setOpenSuccess(true);
							}
						}
					)
				);
			}
		},
	});

	const isDisabledAll = false;

	const handleFileUpload = (file: File) => {
		setOcFile(file);
		if (values.orders_uuid) {
			dispatch(
				upsertFileOrderDiscrepancies(values.orders_uuid, file, (isSuccess) => {
					if (isSuccess) {
						setOpenSuccess(true);
					}
				})
			);
		}
	};

	const handleFileDelete = () => {
		setFieldValue("order_confirmation_file", null);
		setOcFile(null);
	};

	const onSupplierChange = (supplier: ISupplier) => {
		const supplierItems = values.order_item_list.map((O_item) => {
			return {
				...O_item,
				supplier_name: supplier.supplier_name,
			};
		});
		setValues({
			...values,
			supplier_uuid: supplier.supplier_uuid as string,
			supplier_name: supplier.supplier_name as string,
			order_item_list: supplierItems,
		});
		validateForm({
			...values,
			supplier_uuid: supplier.supplier_uuid as string,
			supplier_name: supplier.supplier_name as string,
		});
	};

	React.useEffect(() => {
		batch(() => {
			dispatch(toggleSidebar(false));
			dispatch(fetchSupplierOrderAsync());
		});
	}, []);

	React.useEffect(() => {
		setValues(singleOrderDetails);
	}, [singleOrderDetails]);

	// Order Item Calculations
	React.useEffect(() => {
		let newTotalAmount = 0;
		values.order_item_list.forEach((item) => {
			newTotalAmount +=
				Number(item.quantity_ordered) * Number(item.retail_price);
		});

		setFieldValue("total_amount", newTotalAmount);
	}, [values.order_item_list]);

	React.useEffect(() => {
		return () => {
			dispatch(clearSingleOrderSync());
		};
	}, []);

	return (
		<PageLoader loading={loading === LoadState.InProgress}>
			<PageContainer
				title="Manage Order"
				description="This is inner page"
			>
				<form onSubmit={handleSubmit}>
					<StandardCard heading="Order Information">
						<Grid
							container
							spacing={1}
						>
							<Grid
								item
								xs={12}
								lg={3}
							>
								<SupplierAutoSearch
									uniqueKey="supplier_uuid"
									label="Supplier Name"
									disabled
									value={{
										supplier_uuid: values.supplier_uuid as string,
										supplier_name: values.supplier_name as string,
									}}
									onSelect={(value) => onSupplierChange(value)}
									error={errors.supplier_uuid ? true : false}
								/>
							</Grid>

							<Grid
								item
								xs={12}
								lg={3}
							>
								<TextFieldWithLabel
									heading={"Email"}
									type="text"
									id="mail_id"
									fullWidth
									value={values.supplier_email}
									error={errors.supplier_email ? true : false}
									helperText={errors.supplier_email}
									disabled
								/>
							</Grid>

							<Grid
								item
								xs={12}
								md={5}
							>
								<CustomFormLabel>Upload OC</CustomFormLabel>
								<FileUpload
									accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf"
									value={values.order_confirmation_file || null}
									onChange={handleFileUpload}
									onDelete={handleFileDelete}
								/>
							</Grid>
						</Grid>

						<Grid
							container
							spacing={2}
							marginTop={2}
						>
							<Grid
								item
								xs={12}
								md={12}
							>
								<LabelCard heading="Shipping Address Details" />
								<Grid
									container
									spacing={1}
								>
									{!isDisabledAll && (
										<Grid
											item
											xs={12}
											md={12}
										>
											<TextFieldWithLabel
												heading="Address Line 1"
												id="shipping_address_line_1"
												fullWidth
												disabled={true}
												value={values.shipping_address_line_2}
												// onChange={handleChange}
											/>
										</Grid>
									)}
									<Grid
										item
										xs={12}
										md={6}
									>
										<TextFieldWithLabel
											heading="Address Line 1"
											id="shipping_address_line_1"
											fullWidth
											disabled={true}
											value={values.shipping_address_line_1}
											onChange={handleChange}
										/>
									</Grid>
									<Grid
										item
										xs={6}
										md={6}
									>
										<TextFieldWithLabel
											heading="Address Line 2"
											id="shipping_address_line_2"
											fullWidth
											disabled={true}
											value={values.shipping_address_line_2}
											onChange={handleChange}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										md={6}
									>
										<TextFieldWithLabel
											heading="City"
											id="shipping_city"
											fullWidth
											disabled={true}
											value={values.shipping_city}
											onChange={handleChange}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										md={6}
									>
										<TextFieldWithLabel
											heading="State"
											id="shipping_state"
											fullWidth
											disabled={true}
											value={values.shipping_state}
											onChange={handleChange}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										md={6}
									>
										<TextFieldWithLabel
											heading="Country"
											id="shipping_country"
											fullWidth
											disabled={true}
											value={values.shipping_country}
											onChange={handleChange}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										md={6}
									>
										<TextFieldWithLabel
											heading="Pin Code"
											id="shipping_pincode"
											fullWidth
											disabled={true}
											value={values.shipping_pincode}
											onChange={handleChange}
										/>
									</Grid>
								</Grid>
							</Grid>
							{/* <Grid item xs={12} md={6}>
                <LabelCard heading="Billing Address Details" />
                <Grid container spacing={1}>
                  {!isDisabledAll && (
                    <Grid item xs={12} md={12}>
                      <TextFieldWithLabel
                        heading="Address Line 1"
                        id="billing_address_line_2"
                        fullWidth
                        disabled={true}
                        value={values.billing_address_line_2}
                        // onChange={handleChange}
                      />
                    </Grid>
                  )}
                  <Grid item xs={6} md={6}>
                    <TextFieldWithLabel
                      heading="Address Line 1"
                      id="billing_address_line_1"
                      fullWidth
                      disabled={true}
                      value={values.billing_address_line_1}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextFieldWithLabel
                      heading="Address Line 2"
                      id="billing_address_line_2"
                      fullWidth
                      disabled={true}
                      value={values.billing_address_line_2}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldWithLabel
                      heading="City"
                      id="billing_city"
                      fullWidth
                      disabled={true}
                      value={values.billing_city}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldWithLabel
                      heading="State"
                      id="billing_state"
                      fullWidth
                      disabled={true}
                      value={values.billing_state}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldWithLabel
                      heading="Country"
                      id="billing_country"
                      fullWidth
                      disabled={true}
                      value={values.billing_country}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldWithLabel
                      heading="Pin Code"
                      id="billing_pincode"
                      fullWidth
                      disabled={true}
                      value={values.billing_pincode}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid> */}
						</Grid>

						{/*********************************** Order Items *************************************** */}

						<Grid
							item
							xs={12}
							marginTop={2}
						>
							<LabelCard heading="Order Items" />
							<SupplierOrderItemsTable
								uuid={singleOrderUUID as string}
								data={values.order_item_list}
								supplierName={values.supplier_name}
								onChange={(data) => setFieldValue("order_item_list", data)}
							/>
						</Grid>
					</StandardCard>

					<StandardCard
						sx={{
							marginTop: 2,
							marginBottom: 8,
						}}
					>
						<Grid
							container
							spacing={1}
						>
							<Grid
								item
								xs={12}
								lg={5}
							></Grid>
							<Grid
								item
								xs={12}
								lg={7}
							>
								<Grid
									item
									xs={12}
									lg={12}
								>
									<Stack
										direction={"row"}
										justifyContent={"space-between"}
										marginTop={2}
										marginBottom={2}
										padding={1.5}
										borderRadius={2}
										sx={{ backgroundColor: "lightgrey" }}
									>
										<Typography
											variant="h5"
											sx={{ color: "black" }}
											fontWeight={600}
										>
											Total Amount
										</Typography>
										<Typography
											variant="h5"
											fontWeight={600}
										>
											{displayPriceFormat(values.total_amount)}
										</Typography>
									</Stack>
								</Grid>
								<Grid
									item
									xs={12}
									lg={12}
								>
									<Stack
										display={"flex"}
										flexDirection={"row"}
										justifyContent={"flex-end"}
										alignItems={"flex-end"}
										gap={2}
									>
										{/* <CustomStatusSelector
                      heading="Status"
                      name="status"
                      module="ORDER"
                      value={values.status}
                      onChange={handleChange}
                    /> */}
										<Button
											variant="contained"
											disabled={!ocFile}
											type="submit"
										>
											Save
										</Button>
									</Stack>
								</Grid>
							</Grid>
						</Grid>
					</StandardCard>
				</form>
			</PageContainer>
			{openSuccess && (
				<SuccessDialog
					open={openSuccess}
					content={
						<Typography variant="h4">
							Your file has been successfully uploaded
						</Typography>
					}
					onClose={() => setOpenSuccess(false)}
				/>
			)}
		</PageLoader>
	);
};
