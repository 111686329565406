import { LoadState } from "../../constants/enums";
import { IOrder, IOrderItem, IOrdersState } from "./orders.types";

export const defaultOrderItem: IOrderItem = {
	orders_uuid: "",
	order_item_uuid: "",
	product_uuid: "",
	supplier_name: null,
	product_vpn: null,
	primary_image_url: null,
	style_code: null,
	upc_ean: null,
	brand: null,
	product_name: null,
	description: null,
	category: null,
	taxonomy1: null,
	taxonomy2: null,
	hs_code: null,
	color_code: null,
	color: null,
	size: null,
	quantity_ordered: null,
	season: null,
	purchase_price: 0,
	retail_price: 0,
	currency: null,
	status: "ACTIVE",
};
export const defaultOrder: IOrder = {
	orders_uuid: "",
	supplier_uuid: "",
	supplier_name: "",
	supplier_email: "",
	total_amount: null,
	order_item_list: [],
	shipping_address_line_1: null,
	shipping_address_line_2: null,
	shipping_city: null,
	shipping_state: null,
	shipping_pincode: null,
	shipping_country: null,
	billing_address_line_1: null,
	billing_address_line_2: null,
	billing_city: null,
	billing_state: null,
	billing_pincode: null,
	billing_country: null,
	status: "DRAFT",
	order_no: "",
};

export const defaultOrdersState: IOrdersState = {
	orders_list: {
		loading: LoadState.NotLoaded,
		data: [],
		total_count: 0,
		error: null,
	},
	single_order: {
		loading: LoadState.NotLoaded,
		data: defaultOrder,
		error: null,
	},
	orders_approvals_list: {
		loading: LoadState.NotLoaded,
		data: [],
		total_count: 0,
		error: null,
	},
	single_order_discrepancy: {
		loading: LoadState.NotLoaded,
		data: {
			...defaultOrder,
			order_discrepancies_data: [],
		},
		error: null,
	},
};
