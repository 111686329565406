import { IMessagesState } from "./messages/messages.types";
import { initialMessagesState } from "./messages/messagesState";
import { ICustomizerState } from "./customizer/customizerReducer";
import { ICommonState, defaultCommonState } from "./common/common.types";

import { IUsersState } from "./UserProfileList/userProfile.types";
import { initialUserProfileState } from "./UserProfileList/userprofileState";

import { ICompanyState, initialCompanyState } from "./company/company.types";
import { IQuoteState } from "./quote/quote.types";

import {
	ISecurityState,
	initialSecurityState,
} from "./security/security.types";

import { defaultQuoteState } from "./quote/defaultState";
import { IDataManagementState } from "./DataManagement/dataManagement.types";
import { defaultDataManagementState } from "./DataManagement/defaultState";

import { IApprovalQuoteState } from "./approval/quote";
import { defaultApprovalQuoteState } from "./approval/quote/defaultState";
import { ICommentState, defaultCommentState } from "./comments";

import {
	IApprovalState,
	intitialApprovalState,
} from "./approval/approvals/approvals.types";
import { IEcnState, defaultEcnState } from "./ecn";
import { initialApprovalItemState } from "./approval/productExpiry/defaultState";
import { IApprovalItemState } from "./approval/productExpiry";
import { defaultNcpState, INcpState } from "./ncp";
import { IWorkOrderState, defaultWorkOrderState } from "./workOrder";
import { IProductsState, defaultProductsState } from "./products";
import { initialTemplatesState, ITemplatesState } from "./templates";
import { defaultSupplierState, ISupplierState } from "./supplier";
import { defaultOrdersState, IOrdersState } from "./orders";
import {
	defaultProductDocumentState,
	IProductDocument,
	IProductDocumentState,
} from "./product-documents";
import { IBudgetListState } from "./budget/budget.types";
import { defaultBudgetState } from "./budget/budget.state";

export interface IDropdownListType {
	key: string;
	text: string;
	value: string;
}

export interface IStoreState {
	customizerReducer: ICustomizerState;
	supplier: ISupplierState;
	message: IMessagesState;

	userprofile: IUsersState;
	common: ICommonState;

	company: ICompanyState;
	products: IProductsState;
	ecn: IEcnState;
	ncp: INcpState;
	quotes: IQuoteState;
	security: ISecurityState;
	dataManagement: IDataManagementState;
	approvalQuotes: IApprovalQuoteState;
	approvalItem: IApprovalItemState;
	comment: ICommentState;
	approvals: IApprovalState;
	workOrder: IWorkOrderState;
	templates: ITemplatesState;
	orders: IOrdersState;
	productDocuments: IProductDocumentState;
	budget: IBudgetListState;
}

export const initialStoreState: IStoreState = {
	customizerReducer: {
		activeDir: "ltr",
		activeNavbarBg: "#0b70fb", // This can be any color,
		activeSidebarBg: "#fff", // This can be any color
		activeMode: "light", // This can be light or dark
		activeTheme: "BLACK_THEME", // BLUE_THEME, GREEN_THEME, RED_THEME, BLACK_THEME, PURPLE_THEME, INDIGO_THEME
		SidebarWidth: 240,
		sidebarOpen: true,
	},

	supplier: defaultSupplierState,
	message: initialMessagesState,

	common: defaultCommonState,

	userprofile: initialUserProfileState,

	company: initialCompanyState,
	products: defaultProductsState,
	ecn: defaultEcnState,
	ncp: defaultNcpState,
	quotes: defaultQuoteState,
	security: initialSecurityState,
	dataManagement: defaultDataManagementState,
	approvalQuotes: defaultApprovalQuoteState,
	approvalItem: initialApprovalItemState,
	comment: defaultCommentState,
	approvals: intitialApprovalState,
	workOrder: defaultWorkOrderState,
	templates: initialTemplatesState,
	orders: defaultOrdersState,
	productDocuments: defaultProductDocumentState,
	budget: defaultBudgetState,
};
