import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { APP_EXCEL_FILE_IMPORTS_ROUTE, APP_EXTRACT_PRODUCST_WITH_API, APP_FILE_IMPORTS_ROUTE, APP_PDF_FILE_IMPORTS_ROUTE } from "./ImportLineSheet.constants";
import { ManageSingleFileUploadWithAI } from "./ManageSingleFileUploadWithAI";



const ImportLineSheetPage = Loadable(
    lazy(() =>
        import("./ImportLineSheetPage").then(
            ({ ImportLineSheetPage }) => ({
                default: ImportLineSheetPage,
            }),
        ),
    ),
);
const ImportExcelLineSheet = Loadable(
    lazy(() =>
        import("./ExcelImports/ImportExcelLineSheet").then(
            ({ ImportExcelLineSheet }) => ({
                default: ImportExcelLineSheet,
            }),
        ),
    ),
);

const ExtractProductWithAI = Loadable(
    lazy(() =>
        import("./ExtractProductWithAI/ExtractProductWithAI").then(
            ({ ExtractProductWithAI }) => ({
                default: ExtractProductWithAI,
            }),
        ),
    ),
);



export const fileUploadWithAIRouter = [
    {
        path: `${APP_FILE_IMPORTS_ROUTE}`,
        exact: true,
        element: (
            <AuthorizedRoute allowFullAccess moduleIds={[]}>
                <ImportLineSheetPage />
            </AuthorizedRoute>
        ),
    },
    {
        path: `${APP_EXCEL_FILE_IMPORTS_ROUTE}`,
        exact: true,
        element: (
            <AuthorizedRoute allowFullAccess moduleIds={[]}>
                <ImportExcelLineSheet />
            </AuthorizedRoute>
        ),
    },
    {
        path: `${APP_PDF_FILE_IMPORTS_ROUTE}`,
        exact: true,
        element: (
            <AuthorizedRoute allowFullAccess moduleIds={[]}>
                <ManageSingleFileUploadWithAI />
            </AuthorizedRoute>
        ),
    },
    {
        path: `${APP_EXTRACT_PRODUCST_WITH_API}/:proccessRecordsId`,
        exact: true,
        element: (
            <AuthorizedRoute allowFullAccess moduleIds={[]}>
                <ExtractProductWithAI />
            </AuthorizedRoute>
        ),
    },
]