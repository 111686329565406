import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import {
	saveLoaderCompleted,
	saveLoaderProgress,
	showMessage,
} from "../messages/messagesActions";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { isExternalLogin } from "../../helpers";
import { IBudget } from "./budget.types";
import { Dispatch } from "react";

// ----------------------------------------------------------------------------------------------------
// ----------------------------------------Single Budget Fetch----------------------------------------
// ----------------------------------------------------------------------------------------------------

export const FETCH_SINGLE_BUDGET_PROGRESS = "FETCH_SINGLE_BUDGET_PROGRESS";
export const FETCH_SINGLE_BUDGET_SUCCESS = "FETCH_SINGLE_BUDGET_SUCCESS";
export const FETCH_SINGLE_BUDGET_FAILED = "FETCH_SINGLE_BUDGET_FAILED";

export const fetchSingleBudgetProgress = () =>
	action(FETCH_SINGLE_BUDGET_PROGRESS);

export const fetchSingleBudgetSuccess = (data: IBudget) =>
	action(FETCH_SINGLE_BUDGET_SUCCESS, data);

export const fetchSingleBudgetFailed = (message: string) =>
	action(FETCH_SINGLE_BUDGET_FAILED, message);

/**
 * Fetch a budget by its UUID.
 *
 * @param budget_uuid - The UUID of the budget.
 * @returns A thunk that will fetch the budget and dispatch an action to the
 *          store when it is done.
 */

export const fetchSingleBudgetAsync =
	(
		budget_uuid: string,
		pageNo: number = 1,
		itemPerPage: number = 1
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		if (!budget_uuid) {
			// Handle invalid user_uuid scenario
			dispatch(fetchSingleBudgetFailed("User UUID is required."));
			dispatch(
				showMessage({
					type: "error",
					message: "User UUID is missing or invalid.",
					displayAs: "snackbar",
				})
			);
			return;
		}

		try {
			dispatch(fetchSingleBudgetProgress());

			const url = `/budget/get-budget?budget_uuid=${budget_uuid}&pageNo=${pageNo}&itemPerPage=${itemPerPage}`;
			const response = await api.get(url);
			const data: IBudget[] = response.data.data;

			if (data && data.length > 0) {
				dispatch(fetchSingleBudgetSuccess(data[0]));
			} else {
				dispatch(fetchSingleBudgetFailed("No budget records found."));
				dispatch(
					showMessage({
						type: "warning",
						message: "No budget data found for the given user.",
						displayAs: "snackbar",
					})
				);
			}
		} catch (error: any) {
			const errorMessage =
				error.response?.data?.message || "Failed to fetch budget details.";

			dispatch(fetchSingleBudgetFailed(errorMessage)); // Dispatch failure action
			dispatch(
				showMessage({
					type: "error",
					message: errorMessage,
					displayAs: "snackbar",
				})
			);
		}
	};

export const fetchSingleBudgetByUserUUIDAsync =
	(
		user_uuid: string,
		pageNo: number = 1,
		itemPerPage: number = 1
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		if (!user_uuid) {
			// Handle invalid user_uuid scenario
			dispatch(fetchSingleBudgetFailed("User UUID is required."));
			dispatch(
				showMessage({
					type: "error",
					message: "User UUID is missing or invalid.",
					displayAs: "snackbar",
				})
			);
			return;
		}

		try {
			dispatch(fetchSingleBudgetProgress());

			const url = `/budget/get-budget?user_uuid=${user_uuid}&pageNo=${pageNo}&itemPerPage=${itemPerPage}`;
			const response = await api.get(url);
			const data: IBudget[] = response.data.data;

			if (data && data.length > 0) {
				dispatch(fetchSingleBudgetSuccess(data[0]));
			} else {
				dispatch(fetchSingleBudgetFailed("No budget records found."));
				dispatch(
					showMessage({
						type: "warning",
						message: "No budget data found for the given user.",
						displayAs: "snackbar",
					})
				);
			}
		} catch (error: any) {
			const errorMessage =
				error.response?.data?.message || "Failed to fetch budget details.";

			dispatch(fetchSingleBudgetFailed(errorMessage)); // Dispatch failure action
			dispatch(
				showMessage({
					type: "error",
					message: errorMessage,
					displayAs: "snackbar",
				})
			);
		}
	};

// ----------------------------------------------------------------------------------------------------
// ----------------------------------------Budget List Fetch-------------------------------------------
// ----------------------------------------------------------------------------------------------------

export const FETCH_BUDGET_LIST_PROGRESS = "FETCH_BUDGET_LIST_PROGRESS";
export const FETCH_BUDGET_LIST_SUCCESS = "FETCH_BUDGET_LIST_SUCCESS";
export const FETCH_BUDGET_LIST_FAILED = "FETCH_BUDGET_LIST_FAILED";

export const fetchBudgetListProgress = () => action(FETCH_BUDGET_LIST_PROGRESS);

export const fetchBudgetListSuccess = (data: IBudget[], totalRecords: number) =>
	action(FETCH_BUDGET_LIST_SUCCESS, { data, totalRecords });

export const fetchBudgetListFailed = () => action(FETCH_BUDGET_LIST_FAILED);

export const fetchBudgetListAsync =
	(queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch) => {
		try {
			const searchQuery = getSearchQuery(queryParams);
			dispatch(fetchBudgetListProgress());

			const res = await api.get(`/budget/get-budget${searchQuery}`);
			const data: IBudget[] = res.data.data;
			const totalRecords = res.data.totalRecords;

			dispatch(fetchBudgetListSuccess(data, totalRecords));
		} catch (err: any) {
			dispatch(fetchBudgetListFailed());
			dispatch(
				showMessage({
					type: "error",
					message: err.response?.data?.message || "Failed to fetch budgets",
					displayAs: "snackbar",
				})
			);
		}
	};

// ----------------------------------------------------------------------------------------------------
// ----------------------------------------Upsert single Budget----------------------------------------
// ----------------------------------------------------------------------------------------------------

export const upsertSingleBudgetAsync =
	(
		data: IBudget,
		onCallback: (isSuccess: boolean, budget?: IBudget) => void
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch) => {
		try {
			const {
				insert_ts,
				create_ts,
				created_by_name,
				created_by_uuid,
				modified_by_name,
				modified_by_uuid,
				remaining_amount,
				...rest
			} = data;

			dispatch(saveLoaderProgress());
			const res = await api.post("/budget/upsert-budget", rest); // Adjust endpoint if necessary
			const message = "Budget saved successfully!";
			onCallback(true, res.data.data);
			dispatch(
				showMessage({
					type: "success",
					displayAs: "snackbar",
					message: message,
				})
			);
		} catch (err: any) {
			onCallback(false);
			dispatch(
				showMessage({
					type: "error",
					message: err.response?.data?.message || "Failed to save budget.",
					displayAs: "snackbar",
				})
			);
		} finally {
			dispatch(saveLoaderCompleted());
		}
	};

// ----------------------------------------------------------------------------------------------------
// ----------------------------------------TopUp Budget------------------------------------------------
// ----------------------------------------------------------------------------------------------------

export const topUpBudgetAsync =
	(
		budgetUuid: IBudget,
		amount: number,
		onCallback: (isSuccess: boolean) => void
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch) => {
		try {
			dispatch(saveLoaderProgress());
			const payload = {
				budget_uuid: budgetUuid.budget_uuid,
				amount: amount,
			};

			const res = await api.post("/budget/top-up-budget", payload);

			if (res.data.data) {
				dispatch(
					showMessage({
						type: "success",
						displayAs: "snackbar",
						message: "Budget top-up successful!",
					})
				);
				onCallback(true);
			} else {
				dispatch(
					showMessage({
						type: "error",
						message: res.data.message || "Top-up failed.",
						displayAs: "snackbar",
					})
				);
				onCallback(false);
			}
		} catch (err: any) {
			dispatch(
				showMessage({
					type: "error",
					message: err.response?.data?.message || "An error occurred.",
					displayAs: "snackbar",
				})
			);
			onCallback(false);
		} finally {
			dispatch(saveLoaderCompleted());
		}
	};

// ----------------------------------------------------------------------------------------------------
// ----------------------------------------Fetch Ledger Budget-----------------------------------------
// ----------------------------------------------------------------------------------------------------

export const fetchLedgerBudgetListAsync =
	(
		queryParams: IQueryParams,
		user_uuid: string
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch) => {
		try {
			// Convert queryParams to a search query string
			const searchQuery = getSearchQuery(queryParams);

			dispatch(fetchBudgetListProgress());

			let url = "";

			if (user_uuid !== "-1") {
				url += `&user_uuid=${user_uuid}`;
			}

			// Construct the base URL
			let finalUrl = `/budget/get-budget-ledger${searchQuery}` + url;

			// Append the user_uuid conditionally

			// Fetch data from the API
			const res = await api.get(finalUrl);

			const data = res.data.data;
			const totalRecords = res.data.totalRecords;

			// Dispatch success action
			dispatch(fetchBudgetListSuccess(data, totalRecords));
		} catch (err: any) {
			// Dispatch failure action
			dispatch(fetchBudgetListFailed());

			// Display error message
			dispatch(
				showMessage({
					type: "error",
					message:
						err.response?.data?.message || "Failed to fetch ledger budgets",
					displayAs: "snackbar",
				})
			);
		}
	};

// ----------------------------------------------------------------------------------------------------
// ----------------------------------------Clear Budget------------------------------------------------
// ----------------------------------------------------------------------------------------------------

export const CLEAR_BUDGET = "CLEAR_BUDGET";
export const CLEAR_BUDGET_STATE = "CLEAR_BUDGET_STATE";
export const clearBudget = () => action(CLEAR_BUDGET);
export const clearBudgetState = () => action(CLEAR_BUDGET_STATE);
