import { action } from "typesafe-actions";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { ITemplate } from "./templates.types";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { makeApiCall } from "../../helpers/makeApiCall";
import { uploadFile } from "../../components/FileUpload/utils";
import { IRectangle } from "../../views/template/ImageWithRectanglesSVG.types";

export const FETCH_TEMPLATES_LIST_PROGRESS = "FETCH_TEMPLATES_LIST_PROGRESS";
export const FETCH_TEMPLATES_LIST_SUCCESS = "FETCH_TEMPLATES_LIST_SUCCESS";
export const FETCH_TEMPLATES_LIST_FAILED = "FETCH_TEMPLATES_LIST_FAILED";

export const fetchTemplatesProgress = () =>
  action(FETCH_TEMPLATES_LIST_PROGRESS);
export const fetchTemplatesSuccess = (
  data: ITemplate[],
  totalRecords: number
) => action(FETCH_TEMPLATES_LIST_SUCCESS, { data, totalRecords });
export const fetchTemplatesFailed = () => action(FETCH_TEMPLATES_LIST_FAILED);

export const fetchTemplatesListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDate,
    searchValue: string,
    searchType: string[]
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTemplatesProgress());
      let finalUrl = `/template/get-template?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}`;

      if (searchType.length > 0 && searchValue) {
        finalUrl = `/template/get-template?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${
          date.fromDate
        }&to_date=${date.toDate}&column=${[searchType]}&value=${searchValue}`;
      }
      if (status !== "-1") {
        finalUrl += "&status=" + status;
      }
      const res = await api.get(finalUrl);
      const data: ITemplate[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchTemplatesSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchTemplatesFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_TEMPLATE_PROGRESS = "FETCH_TEMPLATE_PROGRESS";
export const FETCH_TEMPLATE_SUCCESS = "FETCH_TEMPLATE_SUCCESS";
export const FETCH_TEMPLATE_FAILED = "FETCH_TEMPLATE_FAILED";
export const UPDATE_TEMPLATE_IMAGE_PATH = "UPDATE_TEMPLATE_IMAGE_PATH";

export const fetchTemplateProgress = () => action(FETCH_TEMPLATE_PROGRESS);
export const fetchTemplateSuccess = (data: ITemplate) =>
  action(FETCH_TEMPLATE_SUCCESS, { data });
export const fetchTemplateFailed = (errorMessage: string) =>
  action(FETCH_TEMPLATE_FAILED, { errorMessage });

export const updateTemplateImagePath = (path: string) =>
  action(UPDATE_TEMPLATE_IMAGE_PATH, { path });

export const fetchTemplateAsync =
  (template_uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTemplateProgress());
      const res = await api.get(
        `/template/get-template?template_uuid=${template_uuid}&pageNo=1&itemPerPage=10`
      );
      const data = res.data.data;
      if (data.length > 0) {
        dispatch(fetchTemplateSuccess(data[0]));
      } else {
        dispatch(
          fetchTemplateFailed(
            "Unfortunately, there are no records available at the moment."
          )
        );
      }
    } catch (err: any) {
      dispatch(fetchTemplateFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const upsertTemplate =
  (
    data: ITemplate,
    file: File | null,
    onCallback: (isSuccess: boolean, code?: string) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const {
        template_unique_id,
        create_ts,
        insert_ts,
        cropped_images,
        rowId, 
        ...rest
      } = data;
      dispatch(saveLoaderProgress());
      const asPayload = {
        template_unique_no: rest.template_name,
      };
      const path = await uploadFile(
        file,
        "TEMPLATE",
        data.base_file_path as string,
        asPayload
      );
      const res = await makeApiCall(
        {
          url: "/template/upsert-template",
          method: "POST",
        },
        undefined,
        {
          ...rest,
          base_file_path: path,
        }
      );

      const finalDocument: ITemplate = res.data.data;

      dispatch(
        showMessage({
          type: "success",
          message: "Template saved successfully",
          displayAs: "snackbar",
        })
      );
      dispatch(fetchTemplateSuccess(finalDocument));
      onCallback(true, finalDocument.template_uuid as string);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

  export const upsertItemLabels =
  (
   
    onCallback: (isSuccess: boolean, code?: string) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const data = getState().templates.template;
      const {
        template_unique_id,
        create_ts,
        insert_ts,
        cropped_images,
        ...rest
      } = data;
      dispatch(saveLoaderProgress());
      
      const res = await makeApiCall(
        {
          url: "/template/upsert-template",
          method: "POST",
        },
        undefined,
        {
          ...rest,
        }
      );

      const finalDocument: ITemplate = res.data.data;

      dispatch(
        showMessage({
          type: "success",
          message: "Template saved successfully",
          displayAs: "snackbar",
        })
      );
      dispatch(fetchTemplateSuccess(finalDocument));
      onCallback(true, finalDocument.template_uuid as string);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };


export const convertPdfToImage =
  (
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const templateId = getState().templates.template.template_uuid;
      dispatch(saveLoaderProgress());

      const res = await makeApiCall(
        {
          url: "/template/convert-pdf-to-images-template",
          method: "POST",
        },
        undefined,
        {
          template_uuid: templateId,
        }
      );

      const paths: string[] = res.data.data;
      if (paths.length > 0) {
        dispatch(updateTemplateImagePath(paths[0]));
      }

      dispatch(
        showMessage({
          type: "success",
          message: "Template created successfully",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };


export const cropTemplateImage =
  (
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const templateId = getState().templates.template.template_uuid;
      dispatch(saveLoaderProgress());

      const res = await makeApiCall(
        {
          url: "/template/crop-image-template",
          method: "POST",
        },
        undefined,
        {
          template_uuid: templateId,
        }
      );

      const template: ITemplate = res.data.data;

      dispatch(fetchTemplateSuccess(template));

      dispatch(
        showMessage({
          type: "success",
          message: "Template created successfully",
          displayAs: "snackbar",
        })
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const UPDATE_ITEM_LABELING = "UPDATE_ITEM_LABELING";

export const updateLabeling = (rectangles: IRectangle[], currentImageIndex: number) => action(UPDATE_ITEM_LABELING, {
  rectangles,
  currentImageIndex,
})


export const CLEAR_TEMPLATES = "CLEAR_TEMPLATES";
export const clearTemplateState = () => action(CLEAR_TEMPLATES);
