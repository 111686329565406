import { lazy } from "react";
import Loadable from "../../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../../constants/enums";
import { AuthorizedRoute } from "../../../router/AuthorizedRoute";
import { APP_SUPPLIER_CONTACTS_ROUTE } from "./SupplierContacts.constants";

const SupplierContactsListTable = Loadable(
    lazy(() =>
        import("./SupplierContactsListTable").then(({ SupplierContactsListTable }) => ({
            default: SupplierContactsListTable,
        })),
    ),
);

const ManageSupplierSingleContact = Loadable(
    lazy(() =>
        import("./ManageSupplierSingleContact").then(({ ManageSupplierSingleContact }) => ({
            default: ManageSupplierSingleContact,
        })),
    ),
);

export const supplierContactsRoutes = [
    {
        path: APP_SUPPLIER_CONTACTS_ROUTE,
        exact: true,
        element: (
            <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.USERS]}>
                <SupplierContactsListTable />
            </AuthorizedRoute>
        ),
    },
    {
        path: `${APP_SUPPLIER_CONTACTS_ROUTE}/manage`,
        exact: true,
        element: (
            <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.USERS]}>
                <ManageSupplierSingleContact />
            </AuthorizedRoute>
        ),
    },
    {
        path: `${APP_SUPPLIER_CONTACTS_ROUTE}/manage/:uuid`,
        exact: true,
        element: (
            <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.USERS]}>
                <ManageSupplierSingleContact />
            </AuthorizedRoute>
        ),
    },
];
