import { action } from "typesafe-actions";
import { IQueryParams } from "../common/common.types";
import { ThunkAction } from "redux-thunk";
import {
	IDocumentProcess,
	IProductDocument,
	ITempProduct,
} from "./product-documents.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
	closeLoaderWithMessage,
	openLoaderWithMessage,
	showMessage,
} from "../messages/messagesActions";
import { IProduct } from "../products";
import { IDataTableV2DateState } from "../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import { IDatatableV2AdvancedSearchFilter } from "../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";

export const FETCH_PRODUCT_DOCUMENT_LIST_PROGRESS =
	"FETCH_PRODUCT_DOCUMENT_LIST_PROGRESS";
export const FETCH_PRODUCT_DOCUMENT_LIST_SUCCESS =
	"FETCH_PRODUCT_DOCUMENT_LIST_SUCCESS";
export const FETCH_PRODUCT_DOCUMENT_LIST_FAILED =
	"FETCH_PRODUCT_DOCUMENT_LIST_FAILED";

export const fetchProductDocumentsListProgress = () =>
	action(FETCH_PRODUCT_DOCUMENT_LIST_PROGRESS);
export const fetchProductDocumentsListSuccess = (
	data: IProductDocument[],
	totalRecords: number
) => action(FETCH_PRODUCT_DOCUMENT_LIST_SUCCESS, { data, totalRecords });
export const fetchProductDocumentsListFailed = () =>
	action(FETCH_PRODUCT_DOCUMENT_LIST_FAILED);

export const fetchProductDocumentsListAsync =
	(queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			const searchQuery = getSearchQuery(queryParams);
			dispatch(fetchProductDocumentsListProgress());

			const res = await api.get(`/product/get-product-documents${searchQuery}`);
			const data: IProductDocument[] = res.data.data;

			const totalRecords = res.data.totalRecords;

			dispatch(fetchProductDocumentsListSuccess(data, totalRecords));
		} catch (err: any) {
			dispatch(fetchProductDocumentsListFailed());
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		}
	};

export const CLEAR_PRODUCT_DOCUMENTS_STATE = "CLEAR_PRODUCT_DOCUMENTS_STATE";
export const clearProductDocumentsStateSync = () =>
	action(CLEAR_PRODUCT_DOCUMENTS_STATE);

//#########################################################################################
//#########################################################################################
//#########################################################################################

export const checkProductDocumentProcessAsync =
	(
		process_records_uuid: string,
		onCallback: (isSuccess: boolean, process?: IDocumentProcess) => void
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			const res = await api.get(
				`/product/get-process?process_records_uuid=${process_records_uuid}&pageNo=1&itemPerPage=1`
			);
			const data: IDocumentProcess[] = res.data.data;
			console.log(data[0], " retrun from action data.....");
			onCallback(true, data[0]);
		} catch (err: any) {
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		}
	};

export const FETCH_PRODUCT_VALIDATION_LIST_PROGRESS =
	"FETCH_PRODUCT_VALIDATION_LIST_PROGRESS";
export const FETCH_PRODUCT_VALIDATION_LIST_SUCCESS =
	"FETCH_PRODUCT_VALIDATION_LIST_SUCCESS";
export const FETCH_PRODUCT_VALIDATION_LIST_FAILED =
	"FETCH_PRODUCT_VALIDATION_LIST_FAILED";

export const fetchProductValidationListProgress = () =>
	action(FETCH_PRODUCT_VALIDATION_LIST_PROGRESS);
export const fetchProductValidationListSuccess = (
	data: ITempProduct[],
	totalRecords: number
) => action(FETCH_PRODUCT_VALIDATION_LIST_SUCCESS, { data, totalRecords });
export const fetchProductValidationListFailed = () =>
	action(FETCH_PRODUCT_VALIDATION_LIST_FAILED);

export const fetchProductForValidationListAsync =
	(
		document_uuid: string,
		pageNo: number,
		rowPerPage: number,
		date: IDataTableV2DateState["dates"],
		searchValue: IDatatableV2AdvancedSearchFilter
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			// Dispatch progress actions
			dispatch(fetchProductValidationListProgress());

			let url = "";

			// Append advanced search filter to the URL
			if (searchValue.length > 0) {
				url += `&advanceFilter=${JSON.stringify(searchValue)}`;
			}

			// Append date filters to the URL
			if (date.fromDate) {
				url += `&from_date=${date.fromDate}`;
			}
			if (date.toDate) {
				url += `&to_date=${date.toDate}`;
			}

			// Construct the final URL
			const finalUrl =
				`/product/get-temp-product?product_documents_uuid=${document_uuid}&pageNo=${pageNo}&itemPerPage=${rowPerPage}` +
				url;

			// Fetch data from the API
			const res = await api.get(finalUrl);
			const data: ITempProduct[] = res.data.data;
			const totalRecords = res.data.totalRecords;

			// Dispatch success action with data
			dispatch(fetchProductValidationListSuccess(data, totalRecords));
		} catch (err: any) {
			// Dispatch error actions
			dispatch(fetchProductValidationListFailed());
			dispatch(
				showMessage({
					type: "error",
					message: err.response?.data?.message || "An error occurred",
					displayAs: "snackbar",
				})
			);
		}
	};
