import { Box, Typography } from "@mui/material";
import React from "react";

interface IPreviewImageProps {
	imageUrl: string | null;
}
export const PreviewImage: React.FC<IPreviewImageProps> = ({ imageUrl }) => {
	return (
		<Box
			sx={{
				width: "100%",
				height: "inherit",
				boxSizing: "border-box",
				background: "#dfdfdf",
				minWidth: 100,
				minHeight: 100,
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				padding: imageUrl && imageUrl.length > 0 ? 0 : 2,
				border: "1px solid #757575",
				borderRadius: 1.5,
			}}
		>
			{imageUrl && imageUrl.length > 0 ? (
				<img
					src={imageUrl}
					alt="product-url"
					width={"100%"}
					style={{ borderRadius: 12 }}
				/>
			) : (
				<Typography sx={{ color: "#757575" }}>Preview not available</Typography>
			)}
		</Box>
	);
};
