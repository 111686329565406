import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import {
	saveLoaderCompleted,
	saveLoaderProgress,
	showMessage,
} from "../messages/messagesActions";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import {
	IOrder,
	IOrderDiscrepancy,
	IOrderItem,
	IOrdersState,
} from "./orders.types";
import { isExternalLogin } from "../../helpers";
import { ISupplierDeliveryAddress } from "../supplier/supplier-delivery-address";
import { ITempProduct } from "../product-documents"; // Adjust the import path as necessary
import { uploadFile } from "../../helpers/uploadFile";
import { IDataTableV2DateState } from "../../components/TableV2/preDefinedPlugins/DataTableV2Date/DataTableV2Date.types";
import { IDatatableV2AdvancedSearchFilter } from "../../components/TableV2/preDefinedPlugins/SearchFilter/SearchFilter.types";

export const FETCH_ORDER_LIST_PROGRESS = "FETCH_ORDER_LIST_PROGRESS";
export const FETCH_ORDER_LIST_SUCCESS = "FETCH_ORDER_LIST_SUCCESS";
export const FETCH_ORDER_LIST_FAILED = "FETCH_ORDER_LIST_FAILED";

export const fetchOrdersListProgress = () => action(FETCH_ORDER_LIST_PROGRESS);

export const fetchOrdersListSuccess = (
	data: IOrdersState["orders_list"]["data"],
	totalRecords: number
) => action(FETCH_ORDER_LIST_SUCCESS, { data, totalRecords });
export const fetchOrdersListFailed = () => action(FETCH_ORDER_LIST_FAILED);

export const fetchOrdersListAsync =
	(
		queryParams: IQueryParams,

		searchValue?: IDatatableV2AdvancedSearchFilter
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch) => {
		try {
			const isExternal = isExternalLogin();
			const searchQuery = getSearchQuery(queryParams);
			dispatch(fetchOrdersListProgress());
			let url = `/order/get-orders${searchQuery}`;
			if (searchValue && searchValue.length > 0) {
				url += `&advanceFilter=${JSON.stringify(searchValue)}`;
			}

			if (isExternal) {
				url = `/externalSupplier/get-orders${searchQuery}`;
			}
			const res = await api.get(url);
			const data: IOrdersState["orders_list"]["data"] = res.data.data;

			const totalRecords = res.data.totalRecords;

			dispatch(fetchOrdersListSuccess(data, totalRecords));
		} catch (err: any) {
			dispatch(fetchOrdersListFailed());
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		}
	};

export const FETCH_SINGLE_ORDER_PROGRESS = "FETCH_SINGLE_ORDER_PROGRESS";
export const FETCH_SINGLE_ORDER_SUCCESS = "FETCH_SINGLE_ORDER_SUCCESS";
export const FETCH_SINGLE_ORDER_FAILED = "FETCH_SINGLE_ORDER_FAILED";

export const fetchSingleOrderProgress = () =>
	action(FETCH_SINGLE_ORDER_PROGRESS);
export const fetchSingleOrderSuccess = (data: IOrder) =>
	action(FETCH_SINGLE_ORDER_SUCCESS, { data });
export const fetchSingleOrderFailed = (errorMessage: string) =>
	action(FETCH_SINGLE_ORDER_FAILED, { errorMessage });

export const fetchSingleOrderAsync =
	(uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			dispatch(fetchSingleOrderProgress());
			const url = `/order/get-orders?orders_uuid=${uuid}&pageNo=1&itemPerPage=1`;

			const res = await api.get(url);
			const data: IOrder[] = res.data.data;
			if (data.length > 0) {
				dispatch(fetchSingleOrderSuccess(data[0]));
			} else {
				dispatch(fetchSingleOrderFailed("Oops! We couldn't find any records."));
			}
		} catch (err: any) {
			dispatch(fetchSingleOrderFailed("Something went to be wrong!"));
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		}
	};

export const fetchSupplierOrderAsync =
	(): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			dispatch(fetchSingleOrderProgress());
			let url = `/externalSupplier/get-orders?pageNo=1&itemPerPage=1`;

			const res = await api.get(url);
			const data: IOrder[] = res.data.data;
			if (data.length > 0) {
				dispatch(fetchSingleOrderSuccess(data[0]));
			} else {
				dispatch(fetchSingleOrderFailed("Oops! We couldn't find any records."));
			}
		} catch (err: any) {
			dispatch(fetchSingleOrderFailed("Something went to be wrong!"));
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		}
	};

export const upsertSingleOrderAsync =
	(
		data: IOrder,
		onCallback: (isSuccess: boolean, order?: IOrder) => void
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch) => {
		try {
			const {
				create_ts,
				insert_ts,
				approval_uuid,
				is_user_approver,
				requested_by_uuid,
				...payload
			} = data;
			dispatch(saveLoaderProgress());
			const res = await api.post("/order/upsert-orders", payload);
			let message = "Order saved successfully!";
			onCallback(true, res.data.data);
			dispatch(
				showMessage({
					type: "success",
					displayAs: "snackbar",
					message: message,
				})
			);
		} catch (err: any) {
			onCallback(false);
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		} finally {
			dispatch(saveLoaderCompleted());
		}
	};

const mapToRequiredFields = (product: ITempProduct) => ({
	orders_uuid: product.temp_product_uuid || "",
	order_item_uuid: product.product_documents_uuid || "",
	product_uuid: product.temp_product_uuid || "",
	supplier_name: product.supplier || null,
	product_vpn: product.product_vpn || null,
	primary_image_url: product.primary_image_url || null,
	style_code: product.style_code || null,
	upc_ean: product.upc_ean || null,
	brand: product.brand || null,
	product_name: product.product_name || null,
	description: product.description || null,
	category: product.category || null,
	taxonomy1: product.taxonomy1 || null,
	taxonomy2: product.taxonomy2 || null,
	hs_code: product.hs_code || null,
	color_code: product.color_code || null,
	color: product.color || null,
	size: product.size || null,
	quantity_ordered: product.quantity_ordered || null,
	season: product.season || null,
	purchase_price: parseFloat(product.purchase_price || "0"),
	retail_price: parseFloat(product.retail_price || "0"),
	currency: product.currency || null,
});

export const upsertOrderFromLineSheetAsync =
	(
		data: { supplier_uuid: string; order_item_list: ITempProduct[] },
		onCallback: (isSuccess: boolean, orderUuid?: string) => void
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			dispatch(saveLoaderProgress());
			const payload = {
				supplier_uuid: data.supplier_uuid,
				order_item_list: data.order_item_list.map(mapToRequiredFields),
			};
			const res = await api.post("/order/create-linesheet-order", payload);
			let message = "Order saved successfully!";
			onCallback(true, res.data.data.orders_uuid);
			dispatch(
				showMessage({
					type: "success",
					displayAs: "snackbar",
					message: message,
				})
			);
		} catch (err: any) {
			onCallback(false);
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		} finally {
			dispatch(saveLoaderCompleted());
		}
	};

export const upsertExternalSupplierOrderDiscrepancies =
	(
		data: IOrder,
		onCallback: (isSuccess: boolean, order?: IOrder) => void
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			const { order_item_list, orders_uuid } = data;
			dispatch(saveLoaderProgress());
			const res = await api.post("/externalSupplier/fill-order-discrepancies", {
				orders_uuid: orders_uuid,
				order_item_list: order_item_list,
			});
			let message = "Order discrepancies saved successfully!";
			onCallback(true, res.data.data);
			dispatch(
				showMessage({
					type: "success",
					displayAs: "snackbar",
					message: message,
				})
			);
		} catch (err: any) {
			onCallback(false);
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		} finally {
			dispatch(saveLoaderCompleted());
		}
	};

export const upsertFileOrderDiscrepancies =
	(
		orderUuid: string,
		file: File,
		onCallback: (isSuccess: boolean) => void
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			dispatch(saveLoaderProgress());
			const formData = new FormData();
			formData.append("files", file);
			formData.append("orders_uuid", orderUuid);

			const res = await api.post(
				"/externalSupplier/fill-order-discrepancies",
				formData
			);

			onCallback(true);
		} catch (err: any) {
			onCallback(false);
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		} finally {
			dispatch(saveLoaderCompleted());
		}
	};

export const CLEAR_SINGLE_ORDER = "CLEAR_SINGLE_ORDER";
export const CLEAR_ORDERS_STATE = "CLEAR_ORDERS_STATE";
export const clearSingleOrderSync = () => action(CLEAR_SINGLE_ORDER);
export const clearOrdersStateSync = () => action(CLEAR_ORDERS_STATE);

// ##############################################################################################
// ########################v####### Orders Approval Function ###################################
// ##############################################################################################

export const FETCH_ORDERS_APPROVALS_LIST_PROGRESS =
	"FETCH_ORDERS_APPROVALS_LIST_PROGRESS";
export const FETCH_ORDERS_APPROVALS_LIST_SUCCESS =
	"FETCH_ORDERS_APPROVALS_LIST_SUCCESS";
export const FETCH_ORDERS_APPROVALS_LIST_FAILED =
	"FETCH_ORDERS_APPROVALS_LIST_FAILED";

export const fetchOrdersApprovalsListProgress = () =>
	action(FETCH_ORDERS_APPROVALS_LIST_PROGRESS);

export const fetchOrdersApprovalsListSuccess = (
	data: IOrdersState["orders_list"]["data"],
	totalRecords: number
) => action(FETCH_ORDERS_APPROVALS_LIST_SUCCESS, { data, totalRecords });
export const fetchOrdersApprovalsListFailed = () =>
	action(FETCH_ORDERS_APPROVALS_LIST_FAILED);

export const fetchOrdersApprovalsListAsync =
	(queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			const searchQuery = getSearchQuery(queryParams);
			dispatch(fetchOrdersApprovalsListProgress());
			const res = await api.get(
				`/approval/get-approval${searchQuery}&table_name=latest_orders&status=REQUESTED`
			);
			const data: IOrdersState["orders_list"]["data"] = res.data.data;

			const totalRecords = res.data.totalRecords;

			dispatch(fetchOrdersApprovalsListSuccess(data, totalRecords));
		} catch (err: any) {
			dispatch(fetchOrdersApprovalsListFailed());
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		}
	};

// *********************************order access links***************************************
export const orderAccessLinkHanlder =
	(
		ordersUuid: string,
		onCallback: (isSuccess: boolean) => void
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			await api.post("/order/send-supplier-orders-access-link", {
				orders_uuid: ordersUuid,
			});
			let message = "Order access link sent to supplier!";
			onCallback(true);
			dispatch(
				showMessage({
					type: "success",
					displayAs: "snackbar",
					message: message,
				})
			);
		} catch (err: any) {
			onCallback(false);
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		}
	};

// *********************************delivery address***************************************

export const fetchSupplierAddressDetails =
	(
		supplierUuid: string,
		onCallback: (isSuccess: boolean, data?: ISupplierDeliveryAddress) => void
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			dispatch(saveLoaderProgress());
			const response = await api.get(
				`/supplier/get-supplier-delivery-address?pageNo=1&itemPerPage=10&supplier_uuid=${supplierUuid}`
			);

			let message = "Succefully fetch supplier address";

			let data;
			if (response.data.data.length > 0) data = response.data.data[0];

			onCallback(true, data);
			dispatch(
				showMessage({
					type: "success",
					displayAs: "snackbar",
					message: message,
				})
			);
		} catch (err: any) {
			onCallback(false);
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		} finally {
			dispatch(saveLoaderCompleted());
		}
	};

export const FETCH_ORDER_DISCREPANCIES_LIST_PROGRESS =
	"FETCH_ORDER_DISCREPANCIES_LIST_PROGRESS";
export const FETCH_ORDER_DISCREPANCIES_LIST_SUCCESS =
	"FETCH_ORDER_DISCREPANCIES_LIST_SUCCESS";
export const FETCH_ORDER_DISCREPANCIES_LIST_FAILED =
	"FETCH_ORDER_DISCREPANCIES_LIST_FAILED";

export const fetchOrdersDiscrepanciesListProgress = () =>
	action(FETCH_ORDER_DISCREPANCIES_LIST_PROGRESS);

export const fetchOrdersDiscrepanciesListSuccess = (data: IOrderDiscrepancy) =>
	action(FETCH_ORDER_DISCREPANCIES_LIST_SUCCESS, { data });
export const fetchOrdersDiscrepanciesListFailed = (error: string | null) =>
	action(FETCH_ORDER_DISCREPANCIES_LIST_FAILED, { error });

export const fetchOrdersDiscrepanciesListAsync =
	(orders_uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			dispatch(fetchOrdersDiscrepanciesListProgress());
			const url = `/order/get-order-discrepancies?orders_uuid=${orders_uuid}&pageNo=1&itemPerPage=1`;
			const res = await api.get(url);
			const data: IOrderDiscrepancy = res.data.data;

			dispatch(fetchOrdersDiscrepanciesListSuccess(data));
		} catch (err: any) {
			dispatch(fetchOrdersDiscrepanciesListFailed(err.response.data.message));
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		}
	};

export const upsertOrderDiscrepancies =
	(
		data: IOrderDiscrepancy,
		onCallback: (isSuccess: boolean) => void
	): ThunkAction<void, IStoreState, {}, AnyAction> =>
	async (dispatch, getState) => {
		try {
			dispatch(saveLoaderProgress());
			await api.post(
				"/order/response-order-discrepancies",
				data.order_discrepancies_data
			);
			let message = "Order discrepancy saved successfully!";
			onCallback(true);
			dispatch(
				showMessage({
					type: "success",
					displayAs: "snackbar",
					message: message,
				})
			);
		} catch (err: any) {
			onCallback(false);
			dispatch(
				showMessage({
					type: "error",
					message: err.response.data.message,
					displayAs: "snackbar",
				})
			);
		} finally {
			dispatch(saveLoaderCompleted());
		}
	};

export const CLEAR_ORDERS_APPROVALS_STATE = "CLEAR_ORDERS_APPROVALS_STATE";
export const clearOrdersApprovalsListSync = () =>
	action(CLEAR_ORDERS_APPROVALS_STATE);

export const CLEAR_ORDER_STATE = "CLEAR_ORDER_STATE";
export const clearOrderState = () => action(CLEAR_ORDER_STATE);
