import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { CLEAR_ORDER_STATE, CLEAR_ORDERS_APPROVALS_STATE, CLEAR_ORDERS_STATE, CLEAR_SINGLE_ORDER, FETCH_ORDER_DISCREPANCIES_LIST_FAILED, FETCH_ORDER_DISCREPANCIES_LIST_PROGRESS, FETCH_ORDER_DISCREPANCIES_LIST_SUCCESS, FETCH_ORDER_LIST_FAILED, FETCH_ORDER_LIST_PROGRESS, FETCH_ORDER_LIST_SUCCESS, FETCH_ORDERS_APPROVALS_LIST_FAILED, FETCH_ORDERS_APPROVALS_LIST_PROGRESS, FETCH_ORDERS_APPROVALS_LIST_SUCCESS, FETCH_SINGLE_ORDER_FAILED, FETCH_SINGLE_ORDER_PROGRESS, FETCH_SINGLE_ORDER_SUCCESS } from "./orders.actions";
import { defaultOrdersState } from "./orders.state";
import { IOrderActions } from ".";


export const ordersReducer = (
    state: IStoreState["orders"] = defaultOrdersState,
    action: IOrderActions,
) => {
    switch (action.type) {
        // #########################################################################
        // ############################ orders List ################################
        // #########################################################################
        case FETCH_ORDER_LIST_PROGRESS: {
            const newState = produce(state, (draftState) => {
                draftState.orders_list.loading = LoadState.InProgress;
                draftState.orders_list.data = [];
                draftState.orders_list.total_count = 0;
            });
            return newState;
        }
        case FETCH_ORDER_LIST_SUCCESS: {
            const { data, totalRecords } = action.payload;
            const newState = produce(state, (draftState) => {
                draftState.orders_list.loading = LoadState.Loaded;
                draftState.orders_list.data = data;
                draftState.orders_list.total_count = totalRecords;
            });
            return newState;
        }
        case FETCH_ORDER_LIST_FAILED: {
            const newState = produce(state, (draftState) => {
                draftState.orders_list.loading = LoadState.Failed;
            });
            return newState;
        }


        // #########################################################################
        // ############################ Single orders ##############################
        // #########################################################################

        case FETCH_SINGLE_ORDER_PROGRESS: {
            const newState = produce(state, (draftState) => {
                draftState.single_order.loading = LoadState.InProgress;
            });
            return newState;
        }
        case FETCH_SINGLE_ORDER_SUCCESS: {
            const { data } = action.payload;
            const newState = produce(state, (draftState) => {
                draftState.single_order.loading = LoadState.Loaded;
                draftState.single_order.data = data;
            });
            return newState;
        }
        case FETCH_SINGLE_ORDER_FAILED: {
            const { errorMessage } = action.payload;
            const newState = produce(state, (draftState) => {
                draftState.single_order.loading = LoadState.Failed;
                draftState.single_order.error = errorMessage;
            });
            return newState;
        }

        // #########################################################################
        // ######################### Clear order State #############################
        // #########################################################################
        case CLEAR_SINGLE_ORDER: {
            const newState = produce(state, (draftState) => {
                draftState.single_order.loading = LoadState.NotLoaded;
                draftState.single_order.data = defaultOrdersState["single_order"]["data"];
                draftState.single_order.error = null;
            });
            return newState;
        }

        case CLEAR_ORDERS_STATE: {
            return defaultOrdersState;
        }



        // #########################################################################
        // #################### orders Approvals List #########################
        // #########################################################################
        case FETCH_ORDERS_APPROVALS_LIST_PROGRESS: {
            const newState = produce(state, (draftState) => {
                draftState.orders_approvals_list.loading = LoadState.InProgress;
                draftState.orders_approvals_list.data = [];
                draftState.orders_approvals_list.total_count = 0;
            });
            return newState;
        }
        case FETCH_ORDERS_APPROVALS_LIST_SUCCESS: {
            const { data, totalRecords } = action.payload;
            const newState = produce(state, (draftState) => {
                draftState.orders_approvals_list.loading = LoadState.Loaded;
                draftState.orders_approvals_list.data = data;
                draftState.orders_approvals_list.total_count = totalRecords;
            });
            return newState;
        }
        case FETCH_ORDERS_APPROVALS_LIST_FAILED: {
            const newState = produce(state, (draftState) => {
                draftState.orders_approvals_list.loading = LoadState.Failed;
            });
            return newState;
        }



        case FETCH_ORDER_DISCREPANCIES_LIST_PROGRESS: {
            const newState = produce(state, (draftState) => {
                draftState.single_order_discrepancy.loading = LoadState.InProgress;
                draftState.single_order_discrepancy.data = defaultOrdersState["single_order_discrepancy"]["data"];
                draftState.single_order_discrepancy.error = null;
            });
            return newState;
        }
        case FETCH_ORDER_DISCREPANCIES_LIST_SUCCESS: {
            const { data } = action.payload;
            const newState = produce(state, (draftState) => {
                draftState.single_order_discrepancy.loading = LoadState.Loaded;
                draftState.single_order_discrepancy.data = data;
            });
            return newState;
        }
        case FETCH_ORDER_DISCREPANCIES_LIST_FAILED: {
            const { error } = action.payload;
            const newState = produce(state, (draftState) => {
                draftState.single_order_discrepancy.loading = LoadState.Failed;
                draftState.single_order_discrepancy.data = defaultOrdersState["single_order_discrepancy"]["data"];
                draftState.single_order_discrepancy.error = error;
            });
            return newState;
        }

        // #########################################################################
        // ###################### Clear order approval list########################
        // #########################################################################
        case CLEAR_ORDERS_APPROVALS_STATE: {
            const newState = produce(state, (draftState) => {
                draftState.orders_approvals_list.loading = LoadState.NotLoaded;
                draftState.orders_approvals_list.data = defaultOrdersState["orders_approvals_list"]["data"];
                draftState.orders_approvals_list.error = null;
            });
            return newState;
        }

        case CLEAR_ORDER_STATE: {
            return defaultOrdersState;
        }


        default: {
            return state;
        }
    }
};
