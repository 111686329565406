import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../constants/enums";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { APP_GUIDELINES_ROUTE } from "./guidlines.constants";

const ManageGuidlines = Loadable(
	lazy(() =>
		import("./ManageGuidlines").then(({ ManageGuidlines }) => ({
			default: ManageGuidlines,
		}))
	)
);
export const ManageGuidlinesRoutes = [
	{
		path: APP_GUIDELINES_ROUTE,
		exact: true,
		element: (
			<AuthorizedRoute
				allowFullAccess
				moduleIds={[MODULE_IDS.PRODUCTS]}
			>
				<ManageGuidlines />
			</AuthorizedRoute>
		),
	},
];
