import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { action } from "typesafe-actions";
import { api } from "../../../api/api";
import {
    saveLoaderCompleted,
    saveLoaderProgress,
    showMessage,
} from "../../messages/messagesActions";
import { IQueryParams } from "../../common/common.types";
import { getSearchQuery } from "../../common/helpers";
import { ISupplierBranch, ISupplierBranchState } from "./supplier-branch.types";

export const FETCH_SUPPLIER_BRANCH_LIST_PROGRESS =
    "FETCH_SUPPLIER_BRANCH_LIST_PROGRESS";
export const FETCH_SUPPLIER_BRANCH_LIST_SUCCESS =
    "FETCH_SUPPLIER_BRANCH_LIST_SUCCESS";
export const FETCH_SUPPLIER_BRANCH_LIST_FAILED =
    "FETCH_SUPPLIER_BRANCH_LIST_FAILED";

export const fetchSupplierBranchListProgress = () =>
    action(FETCH_SUPPLIER_BRANCH_LIST_PROGRESS);

export const fetchSupplierBranchListSuccess = (
    data: ISupplierBranchState["supplier_Branch_list"]["data"],
    totalRecords: number,
) => action(FETCH_SUPPLIER_BRANCH_LIST_SUCCESS, { data, totalRecords });
export const fetchSupplierBranchListFailed = () =>
    action(FETCH_SUPPLIER_BRANCH_LIST_FAILED);

export const fetchSupplierBranchListAsync =
    (queryParams: IQueryParams, supllierUUID?: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
        async (dispatch, getState) => {
            try {
                const searchQuery = getSearchQuery(queryParams);
                let final_url = `/supplier/get-supplier-branch${searchQuery}`
                if(supllierUUID) {
                    final_url += `&supplier_uuid=${supllierUUID}`
                }
                dispatch(fetchSupplierBranchListProgress());
                const res = await api.get(final_url);
                const data: ISupplierBranch[] = res.data.data;

                const totalRecords = res.data.totalRecords;

                dispatch(fetchSupplierBranchListSuccess(data, totalRecords));
            } catch (err: any) {
                dispatch(fetchSupplierBranchListFailed());
                dispatch(
                    showMessage({
                        type: "error",
                        message: err.response.data.message,
                        displayAs: "snackbar",
                    }),
                );
            }
        };

export const FETCH_SUPPLIER_BRANCH_PROGRESS = "FETCH_SUPPLIER_BRANCH_PROGRESS";
export const FETCH_SUPPLIER_BRANCH_SUCCESS = "FETCH_SUPPLIER_BRANCH_SUCCESS";
export const FETCH_SUPPLIER_BRANCH_FAILED = "FETCH_SUPPLIER_BRANCH_FAILED";

export const fetchSupplierBranchProgress = () =>
    action(FETCH_SUPPLIER_BRANCH_PROGRESS);
export const fetchSupplierBranchSuccess = (data: ISupplierBranch) =>
    action(FETCH_SUPPLIER_BRANCH_SUCCESS, { data });
export const fetchSupplierBranchFailed = (errorMessage: string) =>
    action(FETCH_SUPPLIER_BRANCH_FAILED, { errorMessage });

export const fetchSupplierBranchAsync =
    (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
        async (dispatch, getState) => {
            try {
                dispatch(fetchSupplierBranchProgress());
                const res = await api.get(
                    `/supplier/get-supplier-branch?supplier_branch_uuid=${uuid}&pageNo=1&itemPerPage=1`,
                );
                const data: ISupplierBranch[] = res.data.data;
                if (data.length > 0) {
                    dispatch(fetchSupplierBranchSuccess(data[0]));
                } else {
                    dispatch(
                        fetchSupplierBranchFailed("Oops! We couldn't find any records."),
                    );
                }
            } catch (err: any) {
                dispatch(fetchSupplierBranchFailed("Something went to be wrong!"));
                dispatch(
                    showMessage({
                        type: "error",
                        message: err.response.data.message,
                        displayAs: "snackbar",
                    }),
                );
            }
        };

export const upsertSupplierBranchAsync =
    (
        data: ISupplierBranch,
        onCallback: (isSuccess: boolean, customer?: ISupplierBranch) => void,
    ): ThunkAction<void, IStoreState, {}, AnyAction> =>
        async (dispatch, getState) => {
            try {
                const { create_ts, insert_ts, ...payload } = data;
                dispatch(saveLoaderProgress());
                const res = await api.post("/supplier/upsert-supplier-branch", payload);
                let message = "Supplier Branch saved successfully!";
                onCallback(true, res.data.data);
                dispatch(
                    showMessage({
                        type: "success",
                        displayAs: "snackbar",
                        message: message,
                    }),
                );
            } catch (err: any) {
                onCallback(false);
                dispatch(
                    showMessage({
                        type: "error",
                        message: err.response.data.message,
                        displayAs: "snackbar",
                    }),
                );
            } finally {
                dispatch(saveLoaderCompleted());
            }
        };


export const FETCH_SUPPLIER_BRANCHES_PROGRESS =
    "FETCH_SUPPLIER_BRANCHES_PROGRESS";
export const FETCH_SUPPLIER_BRANCHES_SUCCESS =
    "FETCH_SUPPLIER_BRANCHES_SUCCESS";
export const FETCH_SUPPLIER_BRANCHES_FAILED = "FETCH_SUPPLIER_BRANCHES_FAILED";

export const fetchSupplierBranchesProgress = () =>
    action(FETCH_SUPPLIER_BRANCHES_PROGRESS);
export const fetchSupplierBranchesSuccess = (
    data: ISupplierBranchState["supplier_Branch_list"]["data"],
    totalRecords: number,
) => action(FETCH_SUPPLIER_BRANCHES_SUCCESS, { data, totalRecords });
export const fetchSupplierBranchesFailed = (errorMessage: string) =>
    action(FETCH_SUPPLIER_BRANCHES_FAILED, { errorMessage });

export const fetchSupplierBranchesAsync =
    (
        uuid: string,
        pageNumber: number,
        rowsPerPage: number,
    ): ThunkAction<void, IStoreState, {}, AnyAction> =>
        async (dispatch, getState) => {
            try {
                dispatch(fetchSupplierBranchesProgress());
                const res = await api.get(
                    `/customer/get-customer-branch?customer_uuid=${uuid}&pageNo=${pageNumber}&itemPerPage=${rowsPerPage}`,
                );
                const data: ISupplierBranch[] = res.data.data;
                const totalRecords = res.data.totalRecords;
                dispatch(fetchSupplierBranchesSuccess(data, totalRecords));
            } catch (err: any) {
                dispatch(fetchSupplierBranchesFailed("Something went to be wrong!"));
                dispatch(
                    showMessage({
                        type: "error",
                        message: err.response.data.message,
                        displayAs: "snackbar",
                    }),
                );
            }
        };


export const CLEAR_SUPPLIER_BRANCH = "CLEAR_SUPPLIER_BRANCH";
export const CLEAR_SUPPLIER_BRANCH_STATE = "CLEAR_SUPPLIER_BRANCH_STATE";
export const clearSupplierBranch = () => action(CLEAR_SUPPLIER_BRANCH);
export const clearSupplierBranchState = () =>
    action(CLEAR_SUPPLIER_BRANCH_STATE);
