import { action } from "typesafe-actions";
import {
  DARK_THEME,
  DIRECTION,
  NAVBAR_BG,
  SIDEBAR_BG,
  THEME_COLOR,
  TOGGLE_SIDEBAR
} from "../constants";

export const setTheme = (payload: any) => action(THEME_COLOR, payload);

export const setDarkMode = (payload: any) => action(DARK_THEME, payload);
export const setNavbarBg = (payload: any) => action(NAVBAR_BG, payload);

export const setSidebarBg = (payload: any) => action(SIDEBAR_BG, payload);

export const setDir = (payload: any) => action(DIRECTION, payload);

export const toggleSidebar = (payload: any) => action(TOGGLE_SIDEBAR, payload);

