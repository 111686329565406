import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../constants/enums";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { APP_ORDERS_ROUTE } from "./Orders.constants";
import { SupplierManageOrder } from "./SupplierManageOrder";

const OrdersListTable = Loadable(
    lazy(() =>
        import("./OrdersListTable").then(({ OrdersListTable }) => ({
            default: OrdersListTable,
        })),
    ),
);
const ManageSingleOrder = Loadable(
    lazy(() =>
        import("./ManageSingleOrder").then(({ ManageSingleOrder }) => ({
            default: ManageSingleOrder,
        })),
    ),
);

const OrderDiscrepancy = Loadable(
    lazy(() =>
        import("./OrderDiscrepancy").then(({ OrderDiscrepancy }) => ({
            default: OrderDiscrepancy,
        })),
    ),
);


export const ordersRoutes = [
    {
        path: APP_ORDERS_ROUTE,
        exact: true,
        element: (
            <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.ORDER]}>
                <OrdersListTable />
            </AuthorizedRoute>
        ),
    },
    {
        path: `${APP_ORDERS_ROUTE}/manage`,
        exact: true,
        element: (
            <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.ORDER]}>
                <ManageSingleOrder />
            </AuthorizedRoute>
        ),
    },
    {
        path: `${APP_ORDERS_ROUTE}/manage/:uuid`,
        exact: true,
        element: (
            <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.ORDER]}>
                <ManageSingleOrder />
            </AuthorizedRoute>
        ),
    },
    {
        path: `${APP_ORDERS_ROUTE}/external/manage`,
        exact: true,
        element: (
            <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.ORDER]}>
                <SupplierManageOrder />
            </AuthorizedRoute>
        ),
    },
    {
        path: `${APP_ORDERS_ROUTE}/discrepancy/:uuid`,
        exact: true,
        element: (
            <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.ORDER]}>
                <OrderDiscrepancy />
            </AuthorizedRoute>
        ),
    },

];
