import { LoadState } from "../../constants/enums";
import { defaultProductAddOnState } from "./product-addon";
import { defaultProductTemplateState } from "./product-templates";
import { IProduct, IProductsState } from "./products.types";

export const defaultProduct: IProduct = {
	product_uuid: "",
	temp_product_uuid: "",
	product_documents_uuid: "",
	product_vpn: null,
	primary_image_url: null,
	additional_image_urls: [],
	preview__url: null,
	primary_image_urls: [],
	style_code: null,
	upc_ean: null,
	brand: null,
	product_name: null,
	description: null,
	category: null,
	taxonomy1: null,
	taxonomy2: null,
	hs_code: null,
	color_code: null,
	color: null,
	size: null,
	quantity_ordered: null,
	usage_specificity: null,
	supplier_site: null,
	purchase_price: null,
	rsp: null,
	gender: null,
	age_group: null,
	season: null,
	area: null,
	zone: null,
	cost_price: null,
	retail_price: null,
	sale_price: null,
	currency: null,
	stock_level: null,
	reorder_point: null,
	supplier: null,
	lead_time: null,
	shipping_weight: null,
	shipping_dimensions: null,
	fragile_item_flag: null,
	hazardous_material_flag: null,
	eco_friendly_certifications: null,
	fair_trade_status: null,
	organic_status: null,
	vegan_cruelty_free_status: null,
	physical_color: null,
	physical_size: null,
	physical_dimensions: null,
	physical_weight: null,
	physical_material_composition: null,
	fashion_specific_attributes: {
		style: null,
		pattern: null,
		neckline: null,
		sleeve_length: null,
		fit: null,
		occasion: null,
		fabric_type: null,
		care_instructions: null,
	},
	beauty_specific_attributes: {
		skin_type: null,
		finish: null,
		coverage: null,
		ingredient_list: null,
		fragrance_notes: null,
		spf_rating: null,
		shelf_life: null,
		volume_weight: null,
	},
	additional_property: {},
	status: null,
};

export const defaultProductsState: IProductsState = {
	list: [],
	totalRecords: 0,
	loading: LoadState.NotLoaded,
	product: defaultProduct,
	productLoading: LoadState.NotLoaded,
	error: null,
	...defaultProductTemplateState,
	...defaultProductAddOnState,
};
