import { IQuoteLayout } from "../redux/quote";

export const isNotNull = (value: string | number | null | undefined) => {
	return Number(value) || 0;
};

export const calculateDiscount = (amount: number, tax: number) => {
	return (amount * tax) / 100;
};

export const calculateDiscountV2 = (
	amount: string | number | null | undefined,
	tax: string | number | null | undefined
) => {
	let discount = (isNotNull(amount) * isNotNull(tax)) / 100;
	let remainingAmount = isNotNull(amount) - discount;
	return { discount, remainingAmount };
};

export const calculatePercentage = (
	amount: string | number | null | undefined,
	totalAmount: string | number | null | undefined
) => {
	return (100 - (isNotNull(amount) / isNotNull(totalAmount)) * 100).toPrecision(
		10
	);
};

export const calculateTax = (amount: number, tax: number) => {
	return (amount * tax) / 100;
};

export const calculateTaxV2 = (
	amount: string | number | null | undefined,
	tax: string | number | null | undefined
) => {
	return (isNotNull(amount) * isNotNull(tax)) / 100;
};

export interface ICalculateAllDiscountsInputs {
	quoteLayouts: IQuoteLayout[];
	additionalDiscount: string;
	specialDiscount: string;

	customDuty: string;
	labourCess: string;
	isTypeCGST: boolean;
	igst: string;
	cgst: string;
	sgst: string;
	roundOffCheck: string;
}
export interface ICalculateAllDiscountsOutput {
	allProductsAmountTotal: string;
	discount: string;
	discountedValue: string;
	additionalDiscountValue: string;
	specialDiscountValue: string;
	amountAfterDiscount: string;
	customDutyValue: string;
	labourCessValue: string;
	gstTaxValue: string;
	amountAfterTax: string;
}
export const CalculateAllDiscounts = ({
	quoteLayouts,
	additionalDiscount,
	specialDiscount,
	customDuty,
	labourCess,
	isTypeCGST,
	igst,
	cgst,
	sgst,
	roundOffCheck,
}: ICalculateAllDiscountsInputs): ICalculateAllDiscountsOutput => {
	let totalAmountWithoutDiscount: number = 0;
	quoteLayouts.forEach((layout) => {
		layout.items.forEach((item) => {
			totalAmountWithoutDiscount +=
				isNotNull(item.quantity) * isNotNull(item.price);
		});
	});

	let allProductsAmountTotal: number = 0;
	quoteLayouts.forEach((layout) => {
		layout.items.forEach((item) => {
			allProductsAmountTotal += isNotNull(item.total);
		});
	});

	let amountBeforeDiscount = allProductsAmountTotal;

	let discountedValue = totalAmountWithoutDiscount - allProductsAmountTotal;
	let discountPercentage = calculatePercentage(
		allProductsAmountTotal,
		totalAmountWithoutDiscount
	);

	let additionalDiscountValue = 0;
	if (isNotNull(additionalDiscount) > 0) {
		additionalDiscountValue = calculateDiscount(
			allProductsAmountTotal,
			isNotNull(additionalDiscount)
		);
		allProductsAmountTotal -= additionalDiscountValue;
	}
	let specialDiscountValue = 0;
	if (isNotNull(specialDiscount) > 0) {
		specialDiscountValue = calculateDiscount(
			allProductsAmountTotal,
			isNotNull(specialDiscount)
		);
		allProductsAmountTotal -= specialDiscountValue;
	}

	let amountAfterDiscount = allProductsAmountTotal;

	let totalCustomDuty = calculateTaxV2(allProductsAmountTotal, customDuty);
	let totalLabourCess = calculateTaxV2(allProductsAmountTotal, labourCess);
	let totalGSTTax = 0;
	if (isTypeCGST) {
		const totalCSGST = isNotNull(cgst) + isNotNull(sgst);
		totalGSTTax = calculateTaxV2(allProductsAmountTotal, totalCSGST);
	} else {
		totalGSTTax = calculateTaxV2(allProductsAmountTotal, igst);
	}

	let totalAmountAfterTax =
		allProductsAmountTotal + totalGSTTax + totalCustomDuty + totalLabourCess;
	if (roundOffCheck === "Y") {
		allProductsAmountTotal = Math.round(allProductsAmountTotal);
		totalAmountAfterTax = Math.round(totalAmountAfterTax);
	}

	return {
		allProductsAmountTotal: totalAmountWithoutDiscount.toString(),
		discount: discountPercentage.toString(),
		discountedValue: discountedValue.toString(),
		additionalDiscountValue: additionalDiscountValue.toString(),
		specialDiscountValue: specialDiscountValue.toString(),
		amountAfterDiscount: amountAfterDiscount.toString(),

		customDutyValue: totalCustomDuty.toString(),
		labourCessValue: totalLabourCess.toString(),
		gstTaxValue: totalGSTTax.toString(),
		amountAfterTax: totalAmountAfterTax.toString(),
	};
};

export function displayPriceFormat(value: string | number | null) {
	return isNotNull(value)
		.toString()
		.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}
