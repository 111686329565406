import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../constants/enums";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { PRODUCTS_ROUTE, PRODUCT_TEMPLATES_ROUTE } from "./Product.constants";
import { ProductDetail } from "../detail/Detail";

const ProductsTable = Loadable(
  lazy(() =>
    import("./Products").then(({ Products }) => ({
      default: Products,
    })),
  ),
);
const ManageSingleProduct = Loadable(
  lazy(() =>
    import("./ManageSingleProduct").then(({ ManageSingleProduct }) => ({
      default: ManageSingleProduct,
    })),
  ),
);

const ProductTemplatesTable = Loadable(
  lazy(() =>
    import("./ProductTemplates/ProductTemplatesTable").then(
      ({ ProductTemplatesTable }) => ({
        default: ProductTemplatesTable,
      }),
    ),
  ),
);
const ManageProductTemplate = Loadable(
  lazy(() =>
    import("./ProductTemplates/ManageProductTemplate").then(
      ({ ManageProductTemplate }) => ({
        default: ManageProductTemplate,
      }),
    ),
  ),
);
export const productsRoutes = [
  {
    path: PRODUCTS_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.PRODUCTS]}>
        <ProductsTable />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${PRODUCTS_ROUTE}/manage`,
    exact: true,
    element: (
      <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.PRODUCTS]}>
        <ManageSingleProduct />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${PRODUCTS_ROUTE}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.PRODUCTS]}>
        <ManageSingleProduct />
      </AuthorizedRoute>
    ),
  },

  {
    path: PRODUCT_TEMPLATES_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.PRODUCTS]}>
        <ProductTemplatesTable />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${PRODUCT_TEMPLATES_ROUTE}/manage`,
    exact: true,
    element: (
      <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.PRODUCTS]}>
        <ManageProductTemplate />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${PRODUCT_TEMPLATES_ROUTE}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.PRODUCTS]}>
        <ManageProductTemplate />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/product-detail",
    exact: true,
    element: (
      <AuthorizedRoute allowFullAccess moduleIds={[MODULE_IDS.CONTACTS]}>
        <ProductDetail />
      </AuthorizedRoute>
    ),
  },
];
