import { Box, Button, Chip, Grid, Stack } from "@mui/material";
import { useFormik } from "formik";
import _ from "lodash";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { StandardCard } from "../../components/Cards";
import { FileUpload } from "../../components/FileUpload/FileUpload";
import { PageLoader } from "../../components/PageLoader/PageLoader";
import { PageContainer } from "../../components/container/PageContainer";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../components/formsComponents";
import { useDispatchWrapper } from "../../hooks";
import { Breadcrumb } from "../../layout/fullLayout/breadcrumb/Breadcrumb";
import { IBreadcrumbProps } from "../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { defaultAiPdfUpload } from "../../redux/file-upload-with-ai/file-upload-with-ai.state";
import { APP_FILE_IMPORTS_ROUTE } from "./ImportLineSheet.constants";
import { extractProductWithAiAsync, uploadProductDocumentAsync } from "../../redux/file-upload-with-ai/file-upload-with-ai.actions";
import { ExcelFileProductsScreen } from "./components/ExcelFileProductsScreen";
import { PdfFileProductsScreen } from "./components/PdfFileProductsScreen";

const BCrumb: IBreadcrumbProps["items"] = [
  {
    to: "/dashboard",
    title: "dashboard",
  },
  {
    to: APP_FILE_IMPORTS_ROUTE,
    title: "file upload with ai",
  },
  {
    title: "manage",
  },
];

export const getChipBackgroundColor = (accuracy: string) => {
  const acc = Number(accuracy);
  if (acc > 70) {
    return {
      backgroundColor: "#bbedbb",
      color: "#000000",
    };
  }
  return {
    backgroundColor: "#ff6666",
    color: "#ffffff",
  };
};

export const ManageSingleFileUploadWithAI = () => {

  const { uuid } = useParams() as { uuid?: string };
  const isUpdate = uuid ? true : false;

  const dispatch = useDispatchWrapper();

  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [screenType, setScreenType] = useState<"FILE_TYPE" | "UPLOAD_EXCEL" | "UPLOAD_PDF">("UPLOAD_PDF");
  const [documentUUID, setDocumentUUID] = useState<string | null>(null);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    isValid,
    resetForm,
  } = useFormik({
    initialValues: {
      ...defaultAiPdfUpload,
    },
    validate: (values) => {
      const errors: any = {};
      // errors.drawing_items = new Array();

      return errors;
    },
    onSubmit: (values) => {
      // dispatch(upsertAiPdfDocuemntAsync(values, fileToUpload, (isSuccess, data) => {
      //   if (isSuccess && data) {
      //     console.log(data, "data.......>");
      //   }
      // }))
    },
  });


  const handleUploadClick = () => {
    if (!fileToUpload) return;
    setLoading(true);
    try {
      dispatch(
        uploadProductDocumentAsync(
          values,
          (isSuccess, data) => {
            if (isSuccess && data) {
              setDocumentUUID(data.product_documents_uuid)

              if (values.file_type === "PDF") {
                dispatch(extractProductWithAiAsync(data.product_documents_uuid || "", (isSuccess, data: any) => {
                  if (isSuccess && data) {
                    console.log(data, "data.......>");
                    setScreenType("UPLOAD_PDF");
                    setValues(data);
                  }
                }))
              } else if (values.file_type === "EXCEL") {
                setScreenType("UPLOAD_EXCEL");
              }

            }
          },
        ),
      );
    } catch (error) {
      setLoading(false);
    }
  };


  const handleLineShetType = (type: "PDF" | "EXCEL") => () => {
    setScreenType(type === "EXCEL" ? "UPLOAD_EXCEL" : "UPLOAD_PDF")
  }


  return (
    <PageLoader
      loading={false}
      error={null}
    >
      <PageContainer
        title={isUpdate ? "Edit Drawing" : "Create Drawing"}
        description="this is inner page"
      >
        <Breadcrumb title="Drawing Uploads" items={BCrumb} />

        {screenType === "UPLOAD_PDF" ? (
          <PdfFileProductsScreen
            filePath={values.file_path}
            onSaveSuccess={() => setScreenType("FILE_TYPE")}
            onFileUploadSuccess={(path) => setFieldValue("file_path", path)}
          />
        ) : screenType === "UPLOAD_EXCEL" ?
          <ExcelFileProductsScreen onSaveSuccess={() => setScreenType("FILE_TYPE")} />
          :
          (
            <Box component={"form"} onSubmit={handleSubmit}>
              <StandardCard
                sx={{ ml: 2, mr: 2, mb: 2, position: "relative" }}
                heading={`Drawing Information`}
                rightHeading={
                  // <DrawingActionButtons
                  //   handleDownload={handleDownload}
                  //   disabledDowloadButton={disabledDowloadButton}
                  //   isValid={isValid}
                  // />
                  <Button>
                    hwllo
                  </Button>
                }
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <CustomFormLabel>Document name</CustomFormLabel>
                    <CustomTextField
                      name="name"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={values.name}
                      onChange={handleChange}
                      error={errors.name ? true : false}
                      helperText={errors.name}
                    />
                  </Grid>
                </Grid>
              </StandardCard>


            </Box>
          )}
      </PageContainer>
    </PageLoader>
  );
};
